import type { RegisteredUser, User } from 'api/types/users'
import type { PhoneCountryCallingCode } from '../../../../phone/types'
import type { FormValues } from '../types'

function getDefaultPhoneCountryCallingCode(
  user: RegisteredUser | User,
  phoneCountryCallingCodes: PhoneCountryCallingCode[]
): PhoneCountryCallingCode | null {
  const countryCode = user.phone?.countryCode || 'GB'
  const callingCode = user.phone?.callingCode || '44'

  const phoneCountryCallingCode = phoneCountryCallingCodes.find(
    (phoneCountryCallingCode) =>
      phoneCountryCallingCode.countryCode === countryCode &&
      phoneCountryCallingCode.callingCode === callingCode
  )

  return phoneCountryCallingCode || null
}

export function getDefaultValues(
  user: RegisteredUser | User,
  phoneCountryCallingCodes: PhoneCountryCallingCode[]
): FormValues {
  return {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    phoneCountryCallingCode: getDefaultPhoneCountryCallingCode(
      user,
      phoneCountryCallingCodes
    ),
    phoneNumber: user.phone?.number || '',
  }
}

import type { User } from 'api/types/users'
import { useHasFeatureFlag } from 'domains/feature-flag/queries'
import { formatPhone } from 'domains/phone/utils'
import { UpdateUserDetailsDialog } from 'domains/user/components'

import { RouterLink } from 'kitchen/router'
import { formatName } from 'kitchen/utils/helpers'
import {
  Avatar,
  Popover,
  Surface,
  Item,
  Separator,
  SidebarNav,
  Sensitive,
} from 'salad/components'
import { ButtonBase, VStack, Text } from 'salad/primitives'
import { Path } from '@app/constants'
import { useLogout } from '@app/hooks/use-logout'

interface UserActionsProps {
  layout: 'header' | 'sidebar'
  user: User
}

export function UserActions({ layout, user }: UserActionsProps) {
  const logout = useLogout()
  const fullName = formatName(user.firstName, user.lastName)
  const [has2FA] = useHasFeatureFlag(['2FA'])

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        {layout === 'sidebar' ? (
          <SidebarNav.Item size="small">
            <Item.Start>
              <Sensitive>
                <Avatar variant="round" name={fullName || user.email} />
              </Sensitive>
            </Item.Start>
          </SidebarNav.Item>
        ) : (
          <ButtonBase>
            <Avatar variant="round" name={fullName || user.email} />
          </ButtonBase>
        )}
      </Popover.Trigger>
      <Popover.Content align="end" css={{ minWidth: 250, maxWidth: 400 }}>
        {(popoverContext) => (
          <Surface variant="popover" p={16}>
            <VStack gap={12}>
              <Item.Root as="span">
                <Item.Start>
                  <Avatar
                    variant="round"
                    size={fullName ? 'medium' : 'small'}
                    name={fullName || user.email}
                  />
                </Item.Start>
                <Sensitive>
                  <Item.Content>
                    {fullName ? (
                      <VStack>
                        <Text>{fullName}</Text>
                        <Text variant="paragraph-12" color="grey-60">
                          {user.email}
                        </Text>
                        {user.phone && (
                          <Text variant="paragraph-12" color="grey-60">
                            {formatPhone(user.phone)}
                          </Text>
                        )}
                      </VStack>
                    ) : (
                      <VStack>
                        <Text>{user.email}</Text>
                        {user.phone && (
                          <Text variant="paragraph-12" color="grey-60">
                            {formatPhone(user.phone)}
                          </Text>
                        )}
                      </VStack>
                    )}
                  </Item.Content>
                </Sensitive>
              </Item.Root>
              <Separator size={1} px={8} />
              {has2FA ? (
                <Item.Root as={RouterLink} to={Path.USER_SETTINGS}>
                  <Item.Content>Personal settings</Item.Content>
                </Item.Root>
              ) : (
                <UpdateUserDetailsDialog.Root
                  onOpenChange={(open) => {
                    if (!open) {
                      popoverContext.setOpen(false)
                    }
                  }}
                >
                  <UpdateUserDetailsDialog.Trigger asChild>
                    <Item.Root>
                      <Item.Content>Edit details</Item.Content>
                    </Item.Root>
                  </UpdateUserDetailsDialog.Trigger>
                  <UpdateUserDetailsDialog.Content user={user} />
                </UpdateUserDetailsDialog.Root>
              )}
              <Separator size={1} px={8} />
              <Item.Root onClick={() => logout()}>
                <Item.Content>Log out</Item.Content>
              </Item.Root>
            </VStack>
          </Surface>
        )}
      </Popover.Content>
    </Popover.Root>
  )
}

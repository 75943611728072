import type { UserPermission } from 'api/types/permissions'
import { CompanyIdSchema } from 'domains/company/schemas'
import {
  COMMON_ADMIN_PERMISSIONS,
  COMMON_PERMISSIONS,
  PERMISSIONS,
} from 'domains/permissions/constants'
import { z } from 'zod'

export const UserIdSchema = z.string().uuid().brand('UserId')

export const UserRoleSchema = z.enum([
  'ADMIN',
  'APPROVER',
  'BOOKKEEPER',
  'CREATOR',
  'CUSTOM',
  'EMPLOYEE',
  'PAYER',
  'OWNER',
])

export const UserPermissionSchema = z
  .string()
  .refine((value): value is UserPermission => {
    return [...PERMISSIONS, ...COMMON_PERMISSIONS, ...COMMON_ADMIN_PERMISSIONS].includes(
      value
    )
  })

export const TransferOwnershipPayloadSchema = z.union([
  z.object({
    newOwnerUserId: UserIdSchema,
    companyId: CompanyIdSchema,
  }),
  z.object({
    // backoffice schema
    currentOwnerUserId: UserIdSchema,
    newOwnerUserId: UserIdSchema,
    companyId: CompanyIdSchema,
  }),
])

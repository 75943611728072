import type { CountryCode } from 'api/types/address'
import type {
  Beneficiary,
  BeneficiaryFieldName,
  BeneficiaryType,
  PaymentType,
  BeneficiaryLogEntry,
  BeneficiaryLogDetails,
  BeneficiaryAddress,
  LocalBeneficiary,
  BeneficiaryLogState,
} from 'api/types/beneficiaries'
import type { BeneficiaryLogType } from 'domains/beneficiary/types'
import { LOCAL_CURRENCY } from 'domains/payment/constants'
import type { PaymentItem } from 'domains/payment/types'
import type { BatchGroup } from 'domains/payrun/types'
import { BANK_CODE_LENGTH, IBAN_LENGTH } from '../constants'
import { getAddressFieldLabel, getCountryCodeFormatter, isCountryCode } from './address'
import { removeNullableValues } from './data'
import * as formats from './formats'
import { formatName, nonNullable } from './helpers'

export const getBeneficiaryFromBill = (
  paymentItem: PaymentItem
): Beneficiary | undefined =>
  paymentItem.contact.beneficiaries
    .filter((beneficiary) => beneficiary.state !== 'DISABLED')
    .slice()
    .reverse()
    .find((beneficiary) => beneficiary.currency === paymentItem.currencyCode)

export function getBeneficiaryTypeLabel<T extends string>(type: BeneficiaryType | T) {
  switch (type) {
    case 'INDIVIDUAL':
      return 'Individual'
    case 'BUSINESS':
      return 'Business'
    default:
      return type
  }
}

export function getPaymentTypeLabel<T extends string>(
  type: PaymentType | T,
  country: CountryCode | null
) {
  switch (type) {
    case 'LOCAL':
      switch (country) {
        case 'US':
          return 'ACH'
        default:
          return 'Local bank account'
      }
    case 'SWIFT':
      return 'SWIFT'
    default:
      return type
  }
}

export function getBeneficiaryFieldLabel(
  name: keyof Beneficiary | BeneficiaryFieldName | 'bankAccountName'
) {
  switch (name) {
    case 'aba':
      return 'ABA / Routing Number'
    case 'accountNumber':
      return 'Account number'
    case 'bicSwift':
      return 'BIC / SWIFT'
    case 'bankCode':
      return 'Bank Code'
    case 'branchCode':
      return 'Branch Code'
    case 'bsbCode':
      return 'BSB Code'
    case 'clabe':
      return 'CLABE'
    case 'cnaps':
      return 'CNAPS'
    case 'iban':
      return 'IBAN'
    case 'ifsc':
      return 'IFSC'
    case 'sortCode':
      return 'Sort code'
    case 'firstName':
      return 'First name'
    case 'lastName':
      return 'Last name'
    case 'companyName':
      return 'Account name (as shown in the payment details)'
    case 'type':
      return 'Recipient type'
    case 'paymentType':
      return 'Payment type'
    case 'streetLine1':
    case 'streetLine2':
    case 'country':
    case 'city':
    case 'region':
    case 'postcode':
      return getAddressFieldLabel(name)
    case 'bankAccountName':
      return 'Account name (as shown in the payment details)'
    case 'currency':
      return 'Currency'
    default:
      return name
  }
}

export function formatBeneficiaryBankDetails(
  name: keyof Beneficiary,
  value: Beneficiary[keyof Beneficiary] | undefined
) {
  if (typeof value !== 'string') {
    return null
  }

  if (value) {
    switch (name) {
      case 'aba':
      case 'accountNumber':
      case 'bankCode':
      case 'bicSwift':
      case 'branchCode':
      case 'bsbCode':
      case 'clabe':
      case 'cnaps':
      case 'ifsc':
        return value
      case 'sortCode':
        return formats.sortCode(value)
      case 'iban':
        return formats.iban(value)
    }
  }

  return null
}

export function getFilledBeneficiaryBankDetails(beneficiary: Beneficiary | undefined) {
  if (beneficiary === undefined) {
    return {}
  }

  const {
    sortCode,
    accountNumber,
    aba,
    bankCode,
    bicSwift,
    branchCode,
    bsbCode,
    clabe,
    cnaps,
    ifsc,
    iban,
  } = beneficiary

  return removeNullableValues({
    sortCode,
    accountNumber,
    bicSwift,
    aba,
    bankCode,
    branchCode,
    bsbCode,
    clabe,
    cnaps,
    ifsc,
    iban,
  })
}

export function getFilledBeneficiaryAddressDetails(beneficiary: Beneficiary) {
  const { city, region, postcode, streetLine1, streetLine2 } = beneficiary

  return removeNullableValues({
    streetLine1,
    streetLine2,
    city,
    postcode,
    region,
  } satisfies BeneficiaryAddress)
}

export function getBeneficiaryFieldOrder(name: BeneficiaryFieldName | keyof Beneficiary) {
  switch (name) {
    case 'bicSwift':
      return 0
    case 'aba':
    case 'bankCode':
    case 'bsbCode':
    case 'clabe':
    case 'cnaps':
    case 'iban':
    case 'ifsc':
    case 'sortCode':
      return 1
    case 'branchCode':
      return 2
    case 'accountNumber':
      return 3
    case 'address':
      return 4
    default:
      return 1
  }
}

export function getBeneficiaryFields(beneficiary: Beneficiary) {
  return Object.keys(beneficiary).sort(
    (a, b) => getBeneficiaryFieldOrder(a) - getBeneficiaryFieldOrder(b)
  )
}

export function getBeneficiaryFieldMaxLength(name: BeneficiaryFieldName) {
  switch (name) {
    case 'bicSwift':
      return 11
    default:
      return undefined
  }
}

export function getBeneficiaryFieldPlaceholder(
  name: BeneficiaryFieldName,
  country: CountryCode | null
): string | undefined {
  switch (name) {
    case 'aba': {
      return '#########'
    }
    case 'accountNumber': {
      return country === 'GB' ? '########' : undefined
    }
    case 'bankCode': {
      const length = country && BANK_CODE_LENGTH[country]
      return length ? '#'.repeat(length) : undefined
    }
    case 'bicSwift': {
      return 'XXXXXX*****'
    }
    case 'clabe': {
      return '##################'
    }
    case 'cnaps': {
      return '############'
    }
    case 'bsbCode': {
      return '######'
    }
    case 'branchCode': {
      return '#####'
    }
    case 'iban': {
      const length = country && IBAN_LENGTH[country]
      return length ? formats.iban('0'.repeat(length)).replaceAll('0', '#') : undefined
    }
    case 'sortCode': {
      return formats.sortCode('0'.repeat(6)).replaceAll('0', '#')
    }
    default:
      return undefined
  }
}

export const getLatestBeneficiaryLogEntry = (input: BeneficiaryLogEntry[] = []) =>
  input.at(-1)

export const getBatchGroupBeneficiaryLogDetails = (
  group: Pick<BatchGroup, 'contact' | 'currency'>,
  input: BeneficiaryLogDetails[] = []
) =>
  input.find(
    (item) => item.contactId === group.contact.id && item.currency === group.currency
  )

export const checkHasUnmatchedCoP = (input: BeneficiaryLogDetails) => {
  const latest = getLatestBeneficiaryLogEntry(input.beneficiariesLog)
  return (
    latest?.cop !== undefined &&
    latest.hasPayments === false &&
    latest.cop.accountNameMatch !== 'FULL'
  )
}

export const checkHasNewDetails = (input: BeneficiaryLogDetails) => {
  return input.suggestions !== undefined && input.suggestions.length > 0
}

export const checkIsLocalBeneficiary = (
  beneficiary: Beneficiary
): beneficiary is LocalBeneficiary =>
  beneficiary.currency === LOCAL_CURRENCY &&
  beneficiary.country === 'GB' &&
  beneficiary.paymentType === 'LOCAL' &&
  beneficiary.type === 'BUSINESS'

export const isLocalBeneficiary = (beneficiary: Beneficiary) =>
  beneficiary.country === 'GB' && beneficiary.paymentType === 'LOCAL'

const BENEFICIRY_LOG_ENTRY_DEFAULT_KEYS: (keyof Beneficiary)[] = [
  'city',
  'region',
  'postcode',
  'streetLine1',
  'streetLine2',
  'currency',
  'sortCode',
  'accountNumber',
  'bicSwift',
  'iban',
  'aba',
  'bsbCode',
  'bankCode',
  'branchCode',
  'clabe',
  'cnaps',
] as const
const BENEFICIRY_LOG_ENTRY_INTL_KEYS: (keyof Beneficiary)[] = [
  'type',
  'paymentType',
  'country',
] as const
const BENEFICIARY_LOG_ENTRY_BUSINESS_KEYS: (keyof Beneficiary)[] = ['companyName']
const BENEFICIARY_LOG_ENTRY_INDIVIDUAL_KEYS: (keyof Beneficiary)[] = [
  'firstName',
  'lastName',
] as const
const BENEFICIRY_LOG_ENTRY_INDIVIDUAL_CONTACT_FORM_KEYS: (keyof Beneficiary)[] = [
  'firstName',
] as const

export function getBeneficiaryLogEntryStateLabel(state: BeneficiaryLogState) {
  switch (state) {
    case 'OCR':
      return 'Auto-captured'
    case 'PAYROLL':
      return 'Imported from payroll file'
    case 'CREATED':
      return 'Added manually'
    case 'UPDATED':
      return 'Edited'
    default:
      throw new Error(`Unknown beneficiary log state: ${state}`)
  }
}

export function getBeneficiaryLogFieldLabel(
  type: BeneficiaryLogType,
  key: keyof Beneficiary
) {
  if (type === 'contact-form' && key === 'firstName') {
    return 'Name'
  }

  return getBeneficiaryFieldLabel(key)
}

export function formatBeneficiaryLogFieldValue(
  key: keyof Beneficiary,
  value: Beneficiary[keyof Beneficiary],
  beneficiary: Beneficiary,
  type: BeneficiaryLogType
) {
  const formatCountryCode = getCountryCodeFormatter()

  if (typeof value !== 'string') {
    return null
  }

  switch (key) {
    case 'firstName':
      return type === 'contact-form'
        ? formatName(beneficiary.firstName, beneficiary.lastName)
        : value
    case 'type':
      return getBeneficiaryTypeLabel(value)
    case 'paymentType':
      return getPaymentTypeLabel(value, beneficiary.country)
    case 'country':
      if (isCountryCode(value)) {
        return formatCountryCode(value) ?? null
      }

      return null
    default:
      return formatBeneficiaryBankDetails(key, value)
  }
}

export function getBeneficiaryLogEntryKeys(
  entry: BeneficiaryLogEntry,
  type: BeneficiaryLogType
): (keyof Beneficiary)[] {
  const keys = [...BENEFICIRY_LOG_ENTRY_DEFAULT_KEYS]

  if (!isLocalBeneficiary(entry.beneficiary)) {
    keys.push(...BENEFICIRY_LOG_ENTRY_INTL_KEYS)
  }

  if (entry.beneficiary.type === 'BUSINESS') {
    keys.push(...BENEFICIARY_LOG_ENTRY_BUSINESS_KEYS)
  } else {
    if (type === 'contact-form') {
      keys.push(...BENEFICIRY_LOG_ENTRY_INDIVIDUAL_CONTACT_FORM_KEYS)
    } else {
      keys.push(...BENEFICIARY_LOG_ENTRY_INDIVIDUAL_KEYS)
    }
  }

  return keys
}

interface BeneficiaryLogEntryField {
  key: keyof Beneficiary
  label: string
  value: string
  order?: number
}

export function getBeneficiaryLogEntryFields(
  entry: BeneficiaryLogEntry,
  type: BeneficiaryLogType
): BeneficiaryLogEntryField[] {
  const keys = getBeneficiaryLogEntryKeys(entry, type)

  return Object.entries(entry.beneficiary).reduce((fields, [key, value]) => {
    if (keys.includes(key) && nonNullable(value)) {
      const formattedValue =
        formatBeneficiaryLogFieldValue(key, value, entry.beneficiary, type) ?? value
      return formattedValue === null ||
        typeof formattedValue !== 'string' ||
        formattedValue === ''
        ? fields
        : [
            ...fields,
            {
              key,
              label: getBeneficiaryLogFieldLabel(type, key),
              value: formattedValue,
              order: getBeneficiaryFieldOrder(key),
            },
          ]
    }

    return fields
  }, [] as BeneficiaryLogEntryField[])
}

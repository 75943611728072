import { useSyncedRef } from '@react-hookz/web'
import { getIntl } from 'domains/i18n/utils'
import { groupBy as groupData } from 'kitchen/utils/data'
import { matchSorter } from 'match-sorter'
import { useState, useMemo, useDeferredValue } from 'react'
import { match, P } from 'ts-pattern'
import { List, ListItem, Box, VStack, Ellipsis } from '../../primitives'
import { theme } from '../../stitches'
import type { Space } from '../../tokens'
import * as Checkbox from '../checkbox'
import * as Item from '../item'
import { Label } from '../label'
import * as Popover from '../popover'
import { ScrollArea } from '../scroll-area'
import { SearchInput } from '../search-input'
import * as Select from '../select'
import type { SelectOptionContext } from '../select'
import { Sensitive } from '../sensitive'

// Going with automatic search integration depending on number of options
// Consult with Design System before adjusting or adding `searchable` prop
const SEARCH_OPTIONS_SIZE = 8

function getDefaultSingleListboxOption<Value>({
  icon,
  label,
  count,
}: ListboxOption<Value>) {
  return (
    <Item.Root size="small" indicator="highlight">
      {icon && <Item.Start>{icon}</Item.Start>}
      <Ellipsis title={label} asChild>
        <Item.Content>{label}</Item.Content>
      </Ellipsis>
      {count !== null && <Item.End color="black-alpha-40">{count}</Item.End>}
    </Item.Root>
  )
}

function getDefaultMultiListboxOption<Value>(
  { icon, label, count }: ListboxOption<Value>,
  select: SelectOptionContext<Value>
) {
  return (
    <Item.Root size="small" indicator={icon ? 'highlight' : 'none'}>
      <Item.Start>
        {icon === undefined ? (
          <Checkbox.Input checked={select.selected} size={24} readOnly />
        ) : (
          icon
        )}
      </Item.Start>
      <Ellipsis title={label} asChild>
        <Item.Content>{label}</Item.Content>
      </Ellipsis>
      {count !== null && <Item.End color="black-alpha-40">{count}</Item.End>}
    </Item.Root>
  )
}

export interface ListboxGroup {
  key: React.Key
  type: 'group'
  label: string
  count: number
}

export interface ListboxOption<Value> {
  key: React.Key
  value: Value
  label: string
  count: number | null
  type?: 'option'
  icon?: React.ReactElement
  disabled?: boolean
}

export type ListboxItem<Value, Option extends ListboxOption<Value>> =
  | ListboxGroup
  | Option

interface ListboxBaseProps<Value, Option> extends React.AriaAttributes {
  size?: 288 | 320 | 360 | 384 | 450 | 'popover-trigger'
  options: Option[]
  suggested?: Value[]
  searchKeys?: string[]
  /** @private Shouldn't be used outside salad components */
  outsideArea?: number
  /** @private Shouldn't be used outside salad components */
  outsideSpace?: Space
  sensitive?: boolean
  children?: (
    option: Option,
    select: Select.SelectOptionContext<Value>
  ) => React.ReactNode
  groupBy?: (item: Option) => string
  compare?: (a: Value, b: Value) => boolean
}

export interface SingleValueListboxProps<Value, Option>
  extends ListboxBaseProps<Value, Option> {
  value: Value | null
  multiple?: never
  onValueChange: (value: Value | null) => void
}

export interface MultiValueListboxProps<Value, Option>
  extends ListboxBaseProps<Value, Option> {
  value: Value[]
  multiple: true
  onValueChange: (value: Value[]) => void
}

export type ListboxProps<Value, Option> =
  | MultiValueListboxProps<Value, Option>
  | SingleValueListboxProps<Value, Option>

export function Listbox<Value, Option extends ListboxOption<Value>>({
  size = 288,
  options,
  suggested: suggestedValues = [],
  searchKeys = ['label'],
  children,
  outsideArea = 0,
  outsideSpace = 16,
  groupBy,
  sensitive,
  compare,
  ...rest
}: ListboxProps<Value, Option>) {
  const [search, setSearch] = useState('')

  const groupByRef = useSyncedRef(groupBy)
  const searchKeysRef = useSyncedRef(searchKeys)
  const isSearchVisible = options.length > SEARCH_OPTIONS_SIZE

  const popover = Popover.useContext()
  const deferredSearch = useDeferredValue(search.trim())
  const hasSearch = deferredSearch.length > 0

  const getOption =
    children ??
    (rest.multiple ? getDefaultMultiListboxOption : getDefaultSingleListboxOption)

  const filtered = useMemo(
    () =>
      hasSearch
        ? matchSorter(options, deferredSearch, { keys: searchKeysRef.current })
        : options,
    [hasSearch, deferredSearch, options, searchKeysRef]
  )

  const items = useMemo((): ListboxItem<Value, Option>[] => {
    if (groupByRef.current === undefined || hasSearch) {
      return filtered
    }

    const groups: Record<string, Option[]> = groupData(
      filtered,
      groupByRef.current,
      getIntl().locale
    )

    return Object.entries(groups).flatMap(([group, options]) => [
      {
        key: group,
        type: 'group',
        label: group,
        count: options.length,
      },
      ...options,
    ])
  }, [hasSearch, filtered, groupByRef])

  const content = useMemo(
    () =>
      items.map((option) => (
        <Sensitive key={option.key} behavior={sensitive ? 'mask' : 'ignore'}>
          <ListItem>
            {option.type === 'group' ? (
              <Label
                css={{
                  paddingInline: theme.space[8],
                  paddingBlockStart: theme.space[12],
                  paddingBlockEnd: theme.space[2],
                }}
              >
                {option.label}
              </Label>
            ) : (
              <Select.Option value={option.value} disabled={option.disabled}>
                {(select) => getOption(option, select)}
              </Select.Option>
            )}
          </ListItem>
        </Sensitive>
      )),
    [items, getOption, sensitive]
  )

  const suggested = useMemo(() => {
    if (hasSearch) {
      return null
    }

    const suggestedOptions = options.filter(({ value }) =>
      suggestedValues.includes(value)
    )

    return suggestedOptions.map((option) => (
      <Sensitive key={option.key} behavior={sensitive ? 'mask' : 'ignore'}>
        <ListItem>
          <Select.Option value={option.value} disabled={option.disabled}>
            {(select) => getOption(option, select)}
          </Select.Option>
        </ListItem>
      </Sensitive>
    ))
  }, [hasSearch, options, getOption, suggestedValues, sensitive])

  const maxHeight =
    popover === null
      ? 'none'
      : `min(${500 - outsideArea}px, calc(${
          Popover.availableContentHeight
        } - ${outsideArea}px))`

  // space for search + one result
  const minHeight = popover !== null && isSearchVisible ? '116px' : 'none'

  const width = popover === null ? 'auto' : `calc(100vw - 16px)`
  const maxWidth = match({ popover, size })
    .with({ popover: null }, () => 'auto')
    .with({ size: P.number }, (data) => data.size)
    .with({ size: 'popover-trigger' }, () => Popover.triggerWidth)
    .exhaustive()

  return (
    <VStack
      style={{
        gridAutoRows: 'auto 1fr',
        maxHeight,
        minHeight,
      }}
    >
      {isSearchVisible && (
        <Box px={24} pt={outsideSpace} pb={8} style={{ maxWidth }}>
          <SearchInput placeholder="Search" value={search} onValueChange={setSearch} />
        </Box>
      )}
      <ScrollArea variant="custom-scrollbar">
        <Select.Root compare={compare} {...rest}>
          <List
            px={16}
            pt={isSearchVisible ? undefined : outsideSpace}
            pb={outsideSpace}
            css={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridAutoColumns: 'minmax(0px, 1fr)',
              gap: theme.space[2],
              width,
              maxWidth,
            }}
          >
            {suggested}
            {content}
          </List>
        </Select.Root>
      </ScrollArea>
    </VStack>
  )
}

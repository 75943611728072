import { ExternalResource } from 'kitchen/constants'
import { ImageViewer, Link } from 'salad/components'
import * as Icons from 'salad/icons'
import { HStack } from 'salad/primitives'
import type { Attachment } from '../types'
import * as AttachmentStatus from './attachment-status'

function resolveAttachmentURL(input: string) {
  if (input.startsWith(ExternalResource.GOOGLE_STORAGE_BASE)) {
    return input
  }
  return new URL(process.env.API_URL + input, window.location.origin).toString()
}

interface AttachmentPreviewProps {
  value: Attachment
  toolbarPosition?: 'bottom' | 'topLeft'
}

export function AttachmentPreview({
  value,
  toolbarPosition = 'bottom',
}: AttachmentPreviewProps) {
  switch (value.mimeType) {
    case 'application/pdf':
      // Iframe works better with object urls in Safari + Chrome.
      // However in Firefox when you change default PDF viewer in the settings
      // The file will be downloaded instead of displaying inline.
      // Embed works well for Firefox without triggering downloading:
      // https://bugzilla.mozilla.org/show_bug.cgi?id=1662216
      if (navigator.userAgent.includes('Firefox')) {
        return (
          <embed
            src={resolveAttachmentURL(value.url)}
            width="100%"
            height="100%"
            title={value.fileName}
            style={{ border: 'none' }}
          />
        )
      }

      return (
        <iframe
          src={resolveAttachmentURL(value.url)}
          width="100%"
          height="100%"
          title={value.fileName}
          style={{ border: 'none' }}
        />
      )
    // Images supported by web browsers
    // Source: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
    case 'image/apng':
    case 'image/avif':
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
    case 'image/webp':
      return (
        <ImageViewer.Root>
          <ImageViewer.Image src={resolveAttachmentURL(value.url)} />
          <ImageViewer.Toolbar
            position={{ '@initial': 'bottom', '@bp2': toolbarPosition }}
            downloadUrl={resolveAttachmentURL(value.downloadUrl ?? value.url)}
          />
          <ImageViewer.ZoomOnScroll />
        </ImageViewer.Root>
      )
    default:
      return (
        <AttachmentStatus.Root>
          <AttachmentStatus.Title>No preview</AttachmentStatus.Title>
          <AttachmentStatus.Description>
            <Link
              href={resolveAttachmentURL(value.downloadUrl ?? value.url)}
              type={value.mimeType}
              download={value.fileName}
            >
              <HStack as="span" gap={8} css={{ alignItems: 'center' }}>
                <Icons.S16.Download />
                {value.fileName}
              </HStack>
            </Link>
          </AttachmentStatus.Description>
        </AttachmentStatus.Root>
      )
  }
}

import { ImpossibleError } from 'kitchen/utils/error'
import { range } from 'kitchen/utils/helpers'
import { HStack, Box, type BoxProps } from '../../primitives'
import { styled, theme } from '../../stitches'
import type { Color } from '../../tokens'
import { textColor } from '../../variants'

const ProgressBarPill = styled(Box, {
  backgroundColor: theme.colors['grey-10'],
  borderRadius: theme.radii[8].toString(),
  height: theme.space[8],
  transition: 'width 0.3s, background-color 0.3s',
  overflow: 'hidden',
  variants: {
    color: textColor,
    done: { true: { backgroundColor: 'currentColor' } },
  },
})

function getWidth(value: number, total: number) {
  if (total === 0) {
    return value === 0 ? 0 : 100
  }

  return Math.trunc((value / total) * 100)
}

export interface ProgressBarProps extends Omit<BoxProps, 'children'> {
  variant?: 'solid' | 'steps'
  start?: number
  value: number
  total: number
  color?: Color
}

export const ProgressBar = ({
  variant = 'solid',
  start = 0,
  value,
  total,
  color = 'dark-blue',
  style,
  ...rest
}: ProgressBarProps) => {
  const current = Math.max(Math.min(value, total), start)

  switch (variant) {
    case 'solid':
      return (
        <ProgressBarPill
          role="progressbar"
          aria-valuemin={start}
          aria-valuemax={total}
          aria-valuenow={current}
          style={style}
          {...rest}
        >
          <ProgressBarPill
            color={color}
            style={{ width: `${getWidth(current, total)}%` }}
            done
          />
        </ProgressBarPill>
      )
    case 'steps':
      return (
        <HStack
          gap={8}
          role="progressbar"
          aria-valuemin={start}
          aria-valuemax={total}
          aria-valuenow={current}
          style={{ gridAutoColumns: '1fr', ...style }}
          {...rest}
        >
          {range(total, start).map((index) => (
            <ProgressBarPill key={index} done={current >= total - index} color={color} />
          ))}
        </HStack>
      )
    default:
      throw new ImpossibleError('Unhandled variant', variant)
  }
}

import type { GenericForwardRefComponent } from 'kitchen/types'
import { ImpossibleError } from 'kitchen/utils/error'
import { forwardRef } from 'react'
import * as Icons from '../../icons'
import { Grid, Text } from '../../primitives'
import type { FlexProps } from '../../primitives'
import { theme } from '../../stitches'
import * as Button from '../button'
import { IconButton, type IconButtonProps } from '../icon-button'

interface SurfaceHeaderProps extends FlexProps {}

function SurfaceHeader({ children, style, ...rest }: SurfaceHeaderProps) {
  return (
    <Grid
      style={{
        gridTemplate: `
          'back title       end     '
          '.    description description' auto / auto 1fr auto
        `,
        alignItems: 'start',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Grid>
  )
}

interface SurfaceHeaderTitleProps extends Omit<React.HTMLAttributes<unknown>, 'color'> {
  variant?: 'heading' | 'label'
  children?: React.ReactNode
}

function SurfaceHeaderTitle({
  variant = 'heading',
  children,
  ...rest
}: SurfaceHeaderTitleProps) {
  switch (variant) {
    case 'heading':
      return (
        <Text
          as="h3"
          variant="headline-h2"
          color="black"
          style={{ gridArea: 'title' }}
          {...rest}
        >
          {children}
        </Text>
      )
    case 'label':
      return (
        <Text
          as="h3"
          variant="label-16"
          color="grey-60"
          style={{ gridArea: 'title' }}
          {...rest}
        >
          {children}
        </Text>
      )
    default:
      throw new ImpossibleError('Unhandled variant', variant)
  }
}

interface SurfaceHeaderDescriptionProps
  extends React.ComponentPropsWithoutRef<typeof Text> {}

function SurfaceHeaderDescription(props: SurfaceHeaderDescriptionProps) {
  return (
    <Text
      as="p"
      variant="paragraph-16"
      color="black"
      style={{ gridArea: 'description', marginBlockStart: theme.space[4].toString() }}
      {...props}
    />
  )
}

export interface SurfaceHeaderBackProps extends IconButtonProps {}

const SurfaceHeaderBack: GenericForwardRefComponent<SurfaceHeaderBackProps> = forwardRef(
  function SurfaceHeaderBack(props, ref) {
    return (
      <IconButton
        ref={ref}
        size={24}
        style={{
          gridArea: 'back',
          marginBlockStart: theme.space[4].toString(),
          marginInlineEnd: theme.space[16].toString(),
        }}
        {...props}
      >
        <Icons.S24.Arrow variant="left" />
      </IconButton>
    )
  }
)

interface SurfaceHeaderEndProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'ref'> {}

function SurfaceHeaderEnd({ style, ...rest }: SurfaceHeaderEndProps) {
  return (
    <div
      style={{ marginInlineStart: theme.space[16].toString(), gridArea: 'end', ...style }}
      {...rest}
    />
  )
}

interface SurfaceHeaderActionProps extends Button.ButtonProps {}

const SurfaceHeaderAction: GenericForwardRefComponent<SurfaceHeaderActionProps> =
  forwardRef(function SurfaceHeaderAction({ children, ...rest }, ref) {
    return (
      <SurfaceHeaderEnd>
        <Button.Root ref={ref} variant="minor" size="extra-small" hug {...rest}>
          <Button.Content>{children}</Button.Content>
        </Button.Root>
      </SurfaceHeaderEnd>
    )
  })

export {
  SurfaceHeader as Root,
  SurfaceHeaderBack as Back,
  SurfaceHeaderTitle as Title,
  SurfaceHeaderDescription as Description,
  SurfaceHeaderAction as Action,
  SurfaceHeaderEnd as End,
}

import * as formats from 'kitchen/utils/formats'
import { useMemo, useId } from 'react'
import { FormattedMessage } from 'react-intl'
import * as Icons from '../../icons'
import * as InputButton from '../input-button'
import { Listbox } from '../listbox'
import type { ListboxProps, ListboxOption } from '../listbox'
import * as Popover from '../popover'
import type { SelectProps } from '../select'
import { Spinner } from '../spinner'
import { Surface } from '../surface'

export interface ComboboxBaseProps<Option>
  extends Omit<InputButton.InputButtonProps, 'value' | 'children'> {
  loading?: boolean
  placeholder?: string
  getLabel?: (option: Option) => string | number
}

export type ComboboxOption<Value> = ListboxOption<Value>
export type ComboboxProps<Value, Option> = ListboxProps<Value, Option> &
  ComboboxBaseProps<Option>

function getSelectProps<Value, Option>(
  props: ComboboxProps<Value, Option>
): SelectProps<Value> {
  return props.multiple
    ? { value: props.value, onValueChange: props.onValueChange, multiple: true }
    : { value: props.value, onValueChange: props.onValueChange }
}

function getListboxProps<Value, Option>(
  props: ComboboxProps<Value, Option>
): ListboxProps<Value, Option> {
  return {
    ...getSelectProps(props),
    size: props.size,
    compare: props.compare,
    options: props.options,
    suggested: props.suggested,
    searchKeys: props.searchKeys,
    children: props.children,
    outsideArea: props.outsideArea,
    outsideSpace: props.outsideSpace,
    groupBy: props.groupBy,
    sensitive: props.sensitive,
  }
}

function getComboboxProps<Value, Option>({
  size,
  options,
  compare,
  suggested,
  searchKeys,
  children,
  outsideArea,
  outsideSpace,
  groupBy,
  sensitive,
  multiple,
  value,
  onValueChange,
  ...rest
}: ComboboxProps<Value, Option>): ComboboxBaseProps<Option> {
  return rest
}

function defaultGetLabel<Value>(option: ComboboxOption<Value>) {
  return option.label
}

/**
  Based on https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
  @todo Add listbox keyboard navigation with arrows
 */
export function Combobox<
  Value,
  Option extends ComboboxOption<Value> = ComboboxOption<Value>
>(props: ComboboxProps<Value, Option>) {
  const id = useId()
  const {
    options,
    size = 'popover-trigger',
    compare = Object.is,
    ...listboxProps
  } = getListboxProps(props)
  const { loading, placeholder, getLabel, ...comboboxBaseProps } = getComboboxProps(props)

  const selected = useMemo(
    () =>
      options.filter((option) =>
        listboxProps.multiple
          ? listboxProps.value.some((value) => compare(value, option.value))
          : listboxProps.value !== null && compare(listboxProps.value, option.value)
      ),
    [options, listboxProps.multiple, listboxProps.value, compare]
  )

  const hasSelected = selected.length > 0

  return (
    <Popover.Root>
      <Popover.Trigger disabled={loading} asChild>
        {(trigger) => (
          <InputButton.Root id={id} role="combobox" {...comboboxBaseProps}>
            {hasSelected ? (
              <InputButton.Content>
                {formats.items(selected, getLabel ?? defaultGetLabel)}
              </InputButton.Content>
            ) : (
              <InputButton.Placeholder>
                {placeholder ?? (
                  <FormattedMessage id="common.select" defaultMessage="Select" />
                )}
              </InputButton.Placeholder>
            )}
            <InputButton.End>
              {loading ? (
                <Spinner size={16} color="black" />
              ) : (
                <Icons.S16.Dropdown variant={trigger.isOpen ? 'pressed' : 'default'} />
              )}
            </InputButton.End>
          </InputButton.Root>
        )}
      </Popover.Trigger>
      <Popover.Content align="start" collisionPadding={16}>
        <Surface variant="popover">
          <Listbox<Value, Option>
            aria-labelledby={id}
            size={size}
            options={options}
            compare={compare}
            {...listboxProps}
          />
        </Surface>
      </Popover.Content>
    </Popover.Root>
  )
}

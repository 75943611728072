export const Bolt = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7V11H16L12 17V13H7.99997L12 7ZM9.99997 15L4.5 15L14 0.394447V9H19.737L9.99997 23.6055V15Z"
      fill="currentColor"
    />
  </svg>
)

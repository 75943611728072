import { lazy, Suspense } from 'react'
import { Box } from 'salad/primitives'
import type { ConfettiBaseProps } from './confetti-base'

const ConfettiBase = lazy(() =>
  import('./confetti-base').then((mod) => ({ default: mod.ConfettiBase }))
)

interface ConfettiProps extends ConfettiBaseProps {
  size: number
  fallback?: React.ReactNode
}

export const Confetti = ({
  size,
  variant,
  children,
  fallback = <Box style={{ height: size, width: size }} />,
}: ConfettiProps) => (
  <Suspense fallback={fallback}>
    <ConfettiBase variant={variant}>{children}</ConfettiBase>
  </Suspense>
)

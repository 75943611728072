import type { Company, CompanySettings } from 'api/types/companies'
import { getIntl } from 'domains/i18n/utils'
import type { PaymentStats } from 'domains/payment/types'
import { checkHasPayments } from 'domains/payment/utils'
import type { PaymentCycleSetup } from 'domains/payment-cycle/types'
import { isAccountingApp } from 'kitchen/utils/vendor'
import type { GetStartedTask } from '../types'

export function getInitialSetupTasks(company: Company): GetStartedTask[] {
  const { formatMessage } = getIntl()
  return [
    {
      id: 'connect-to-accountant',
      done: company.practices.length > 0,
      title: formatMessage({
        id: 'app.onboarding.onboarding-checklist.connect-to-accountant.title',
        defaultMessage: 'Connect to your accountant',
      }),
      description: formatMessage({
        id: 'app.onboarding.onboarding-checklist.connect-to-accountant.description',
        defaultMessage: `Give your accountant secure access to your Apron account to manage bookkeeping and/or payments.`,
      }),
    },
    {
      id: 'sync-accounting-app',
      done:
        isAccountingApp(company.source) ||
        company.bookkeepingOnboardingStatus === 'PASSED',
      title: formatMessage({
        id: 'app.onboarding.onboarding-checklist.sync-accounting-app.title',
        defaultMessage: 'Sync to your accounting app',
      }),
      description: formatMessage({
        id: 'app.onboarding.onboarding-checklist.sync-accounting-app.description',
        defaultMessage: `Automate expense tracking. Get more accurate financial records, simplify reporting, reduce errors and save time.`,
      }),
    },
  ]
}

export function getUploadEssentialTasks(settings: CompanySettings): GetStartedTask[] {
  const { formatMessage } = getIntl()
  return [
    {
      id: 'download-app',
      done: settings.initialOnboarding.isAppDownloadAcknowledged ?? false,
      title: formatMessage({
        id: 'app.onboarding.onboarding-checklist.download-app.title',
        defaultMessage: 'Download the mobile app',
      }),
      description: formatMessage({
        id: 'app.onboarding.onboarding-checklist.download-app.description',
        defaultMessage: 'Capture receipts and get the best of Apron on the go.',
      }),
    },
    {
      id: 'forward-bill',
      done: settings.initialOnboarding.isDocumentForwardAcknowledged ?? false,
      title: formatMessage({
        id: 'app.onboarding.onboarding-checklist.forward-bill.title',
        defaultMessage: 'Forward a bill',
      }),
      description: formatMessage({
        id: 'app.onboarding.onboarding-checklist.forward-bill.description',
        defaultMessage: `Forward bills as soon as they land to experience the full power of Apron. Details are automatically extracted and all your payables are organised for complete visibility.`,
      }),
    },
  ]
}

export function getMakePaymentTasks(
  settings: CompanySettings,
  cycleSetup: PaymentCycleSetup | null,
  paymentStats: PaymentStats
): GetStartedTask[] {
  const { formatMessage } = getIntl()
  return [
    {
      id: 'organise-bills',
      done: cycleSetup !== null,
      title: formatMessage({
        id: 'app.onboarding.onboarding-checklist.organise-bills.title',
        defaultMessage: 'Organise your bills into batches',
      }),
      description: formatMessage({
        id: 'app.onboarding.onboarding-checklist.organise-bills.description',
        defaultMessage: `Your bills automatically organised into batches that match your workflow.`,
      }),
    },
    {
      id: 'review-batch',
      done: settings.initialOnboarding.isPaymentBatchReviewed,
      title: formatMessage({
        id: 'app.onboarding.onboarding-checklist.review-batch.title',
        defaultMessage: 'Review your first batch',
      }),
      description: formatMessage({
        id: 'app.onboarding.onboarding-checklist.review-batch.description',
        defaultMessage: `Learn how paying bills in batches can reduce admin time by up to 90%.`,
      }),
    },
    {
      id: 'create-payment',
      done: checkHasPayments(paymentStats),
      title: formatMessage({
        id: 'app.onboarding.onboarding-checklist.create-payment.title',
        defaultMessage: 'Make your first batch payment',
      }),
      description: formatMessage({
        id: 'app.onboarding.onboarding-checklist.create-payment.description',
        defaultMessage: `Pay bills at your convenience, or involve the team in approval workflows.`,
      }),
    },
  ]
}

export function getTasks(
  company: Company,
  settings: CompanySettings,
  cycleSetup: PaymentCycleSetup | null,
  paymentStats: PaymentStats
): GetStartedTask[] {
  return [
    ...getInitialSetupTasks(company),
    ...getUploadEssentialTasks(settings),
    ...getMakePaymentTasks(settings, cycleSetup, paymentStats),
  ]
}

import { forwardRef } from 'react'
import type { PathPattern } from 'react-router'
import { Link as RouterLink, useResolvedPath, type LinkProps } from 'react-router-dom'
import { useMatchesPatterns } from './matches-patterns'

interface ActiveRouterLinkProps extends LinkProps {
  match?: PathPattern[]
  exclude?: PathPattern[]
}

export const ActiveRouterLink = forwardRef<HTMLAnchorElement, ActiveRouterLinkProps>(
  function ActiveRouterLinkRef({ children, to, match, ...props }, forwardedRef) {
    const resolved = useResolvedPath(to)
    const matches = useMatchesPatterns(
      match ?? [{ path: `${resolved.pathname}/*`, end: true }]
    )
    const matchesExclude = useMatchesPatterns(props.exclude ?? [])

    return (
      <RouterLink
        ref={forwardedRef}
        to={to}
        aria-current={matches && !matchesExclude}
        {...props}
      >
        {children}
      </RouterLink>
    )
  }
)

import type { ApiRequest } from 'kitchen/types'
import { checkIsNotFoundError } from 'kitchen/utils/error'
import {
  PaymentCycleSetupResponseSchema,
  PaymentCyclesResponseSchema,
  PaymentCycleSetupScheduleResponseSchema,
  CreatePaymentCycleResponseSchema,
  PaymentCyclesNotificationSettingsSchema,
} from './schemas'
import type {
  PaymentCycleSetupPayload,
  PaymentCycleSetupResponse,
  CreatePaymentCycleSetupPayload,
  CreatePaymentCycleSetupResponse,
  UpdatePaymentCycleSetupPayload,
  UpdatePaymentCycleSetupResponse,
  DeletePaymentCycleSetupPayload,
  DeletePaymentCycleSetupResponse,
  PaymentCyclesPayload,
  PaymentCyclesResponse,
  PaymentCycleSetupSchedulePayload,
  PaymentCycleSetupScheduleResponse,
  CreatePaymentCyclePayload,
  CreatePaymentCycleResponse,
  PaymentCyclesNotificationSettings,
  PaymentCyclesNotificationSettingsPayload,
  UpdatePaymentCyclesNotificationSettingsPayload,
} from './types'

export const getPaymentCycleSetup: ApiRequest<
  PaymentCycleSetupPayload,
  PaymentCycleSetupResponse
> = (fetch, { companyId }, signal) =>
  fetch
    .get(`/cycles/${companyId}/setup`, { signal })
    .json<unknown>()
    .then(PaymentCycleSetupResponseSchema.parseAsync)
    .catch((error) => {
      if (checkIsNotFoundError(error)) {
        return null
      }

      throw error
    })

export const createPaymentCycleSetup: ApiRequest<
  CreatePaymentCycleSetupPayload,
  CreatePaymentCycleSetupResponse
> = (fetch, { companyId, ...json }) =>
  fetch
    .post(`/cycles/${companyId}/setup`, {
      json: {
        dayOfMonth: null,
        dayOfWeek: null,
        ...json,
      },
    })
    .void()

export const updatePaymentCycleSetup: ApiRequest<
  UpdatePaymentCycleSetupPayload,
  UpdatePaymentCycleSetupResponse
> = (fetch, { companyId, ...json }) =>
  fetch
    .patch(`/cycles/${companyId}/setup`, {
      json: {
        dayOfMonth: null,
        dayOfWeek: null,
        ...json,
      },
    })
    .void()

export const deletePaymentCycleSetup: ApiRequest<
  DeletePaymentCycleSetupPayload,
  DeletePaymentCycleSetupResponse
> = (fetch, { companyId }) => fetch.delete(`/cycles/${companyId}/setup`).void()

export const getPaymentCycles: ApiRequest<PaymentCyclesPayload, PaymentCyclesResponse> = (
  fetch,
  { companyId, ...filters },
  signal
) =>
  fetch
    .get(`/cycles/${companyId}`, { signal, params: { ...filters } })
    .json<unknown>()
    .then(PaymentCyclesResponseSchema.parseAsync)

export const getPaymentCyclesSetupSchedule: ApiRequest<
  PaymentCycleSetupSchedulePayload,
  PaymentCycleSetupScheduleResponse
> = (fetch, { companyId, from, limit }, signal) =>
  fetch
    .get(`/cycles/${companyId}/setup/schedule`, { signal, params: { from, limit } })
    .json<unknown>()
    .then(PaymentCycleSetupScheduleResponseSchema.parseAsync)

export const createPaymentCycle: ApiRequest<
  CreatePaymentCyclePayload,
  CreatePaymentCycleResponse
> = (fetch, { companyId, plannedDate }, signal) =>
  fetch
    .post(`/cycles/${companyId}`, { signal, json: { plannedDate } })
    .json<unknown>()
    .then(CreatePaymentCycleResponseSchema.parseAsync)

export const getPaymentCyclesNotificationSettings: ApiRequest<
  PaymentCyclesNotificationSettingsPayload,
  PaymentCyclesNotificationSettings
> = (fetch, { companyId }, signal) =>
  fetch
    .get(`/companies/${companyId}/settings/notification-settings/cycles`, { signal })
    .json<unknown>()
    .then(PaymentCyclesNotificationSettingsSchema.parseAsync)

export const updatePaymentCyclesNotificationSettings: ApiRequest<
  UpdatePaymentCyclesNotificationSettingsPayload,
  void
> = (fetch, { companyId, ...json }) =>
  fetch
    .post(`/companies/${companyId}/settings/notification-settings/cycles`, { json })
    .void()

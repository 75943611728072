import type { Company, CompanyId } from 'api/types/companies'
import type { UserPermission } from 'api/types/permissions'
import { allocate, multiply } from 'dinero.js'
import type {
  Subscription,
  PricingPlanPeriod,
  PricingPlanLimitType,
  CompanySubscription,
  ActiveSubscription,
  SubscriptionAddon,
  ActiveSubscriptionAddon,
  EndedSubscriptionAddon,
  TerminatedSubscription,
  BillingInvoice,
} from 'domains/billing/types'
import { getIntl, getDateFormat, getRelativeDayFormat } from 'domains/i18n/utils'
import type { Money } from 'domains/money/types'
import { toDinero, toMoney } from 'domains/money/utils'
import type { PaymentStats } from 'domains/payment/types'
import { checkHasPayments } from 'domains/payment/utils'
import { getFlowTypeLabel } from 'domains/payrun/utils'
import { Timestamp, Locale, StorageKey } from 'kitchen/constants'
import { ImpossibleError } from 'kitchen/utils/error'
import { pluralize } from 'kitchen/utils/formats'
import { ensure, unique, toTypedArray } from 'kitchen/utils/helpers'
import {
  diffDays,
  toDate,
  getUTCTime,
  getNextMonth,
  startOfMonth,
} from 'kitchen/utils/native-date'
import { match, P } from 'ts-pattern'

export const getPayerType = (
  practiceId: CompanyId | undefined,
  subscription: Subscription | null
) => {
  if (subscription === null) {
    return 'NONE'
  }

  if (subscription.payerId === practiceId) {
    return 'PRACTICE'
  }

  if (subscription.payerId === subscription.subscriberId) {
    return 'COMPANY'
  }

  if (subscription.payerId !== subscription.subscriberId) {
    return 'OTHER'
  }

  throw new Error('Impossible subscription payer state')
}

export const getPayerName = (
  practiceId: CompanyId | undefined,
  subscription: Subscription | null
) => {
  const payerType = getPayerType(practiceId, subscription)
  switch (payerType) {
    case 'COMPANY':
      return 'Client'
    case 'PRACTICE':
      return 'Practice'
    case 'OTHER':
      return 'Other practice'
    case 'NONE':
      return '–'
    default:
      throw new ImpossibleError('Unhandled payer type', payerType)
  }
}

export function getPeriodLabel(input: PricingPlanPeriod) {
  switch (input) {
    case '1M':
      return 'month'
    case '1Y':
      return 'year'
    default:
      throw new ImpossibleError('Unhandled period', input)
  }
}

export function getBillingCycleLabel(input: PricingPlanPeriod) {
  switch (input) {
    case '1M':
      return 'Monthly'
    case '1Y':
      return 'Yearly'
    default:
      throw new ImpossibleError('Unhandled period', input)
  }
}

export function getPricingPlanUsageLabel(type: PricingPlanLimitType) {
  switch (type) {
    case 'CAPTURE':
      return getIntl().formatMessage({
        id: 'billing.plan-usage-label.capture',
        defaultMessage: 'Captured documents',
      })
    default:
      return `${getFlowTypeLabel(type)} payments`
  }
}

interface PricingPlanOverageMessageOptions {
  type: PricingPlanLimitType
  overage: number
  fee: string
}

export function getPricingPlanOverageMessage({
  type,
  fee,
  overage,
}: PricingPlanOverageMessageOptions) {
  switch (type) {
    case 'CAPTURE':
      return `${fee} overage fee for ${pluralize(
        overage,
        'additional document',
        'additional documents'
      )}.`
    default:
      const label = getFlowTypeLabel(type).toLowerCase()
      return `${fee} overage fee for ${overage} additional ${label}.`
  }
}

export const getRemainingPrice = (input: Money, remaining: number, days: number) =>
  toMoney(allocate(toDinero(input), [remaining, days - remaining])[0])

export const getOverage = (input: Money, count: number) =>
  toMoney(multiply(toDinero(input), count))

export const checkIsActiveSubscription = (input: Subscription) => input.state === 'ACTIVE'

export function getSortedSubscriptions<Item extends Subscription>(input: Item[]) {
  return input
    .slice()
    .sort((a, b) =>
      a.startDate === null || b.startDate === null
        ? 0
        : a.startDate.localeCompare(b.startDate, undefined, { numeric: true })
    )
}

export const getSortedActiveSubscriptions = (list: Subscription[]) =>
  getSortedSubscriptions(list.filter(checkIsActiveSubscription))

export const getSubscribers = (input: Subscription[]) =>
  unique(input.map((item) => item.subscriberId))

export const getCompanySubscriptions = (
  companyId: CompanyId,
  subscriptions: Subscription[]
) => subscriptions.filter((item) => item.subscriberId === companyId)

export const getSortedCompanySubscriptions = (
  companyId: CompanyId,
  subscriptions: Subscription[]
) =>
  getSortedSubscriptions(subscriptions.filter((item) => item.subscriberId === companyId))

export function getCompanyActiveAndNextSubscriptions(
  companyId: CompanyId,
  subscriptions: Subscription[]
): [current: ActiveSubscription | undefined, next: ActiveSubscription | undefined] {
  const result = getSortedActiveSubscriptions(
    getCompanySubscriptions(companyId, subscriptions)
  )

  return [result[0], result[1]]
}

export const getCompanyExpiredSubscription = (
  companyId: CompanyId,
  subscriptions: Subscription[]
) =>
  getSortedCompanySubscriptions(companyId, subscriptions).findLast(
    checkIsSubscriptionExpired
  )

export const getCompanyActiveSubscription = (
  companyId: CompanyId,
  subscriptions: Subscription[]
) => getCompanyActiveAndNextSubscriptions(companyId, subscriptions)[0]

export function getSubscribersSubtitle(
  company: Company,
  subscribers: CompanyId[],
  subscriptions: Subscription[]
) {
  if (subscribers.length === 1 && subscriptions.length > 0) {
    return subscriptions[0].subscriberName
  }

  switch (company.type) {
    case 'PRACTICE':
      return pluralize(subscribers.length, 'client', 'clients')
    case 'BUSINESS':
    case 'UNDEFINED':
      if (subscriptions.length === 0) {
        return company.name
      }

      return pluralize(subscribers.length, 'company', 'companies')
    default:
      return null
  }
}

export const checkIsSubscriptionExpired = (
  input: Subscription
): input is TerminatedSubscription =>
  input.state === 'TERMINATED' ||
  (Boolean(input.expirationDate) &&
    Timestamp.NOW >= getUTCTime(toDate(input.expirationDate)))

export const checkHasActiveSubscription = (
  input: Subscription | null
): input is Subscription => input !== null && !checkIsSubscriptionExpired(input)

export function checkIsSubscriptionsCancellable(
  active: CompanySubscription | undefined,
  next: CompanySubscription | undefined
) {
  return (
    (active !== undefined &&
      active.pricingPlan.name !== 'Trial' &&
      active.pricingPlan.name !== 'Free' &&
      active.expirationDate === undefined) ||
    next !== undefined
  )
}

export function getExpirationDate(subscription: CompanySubscription | null = null) {
  if (subscription === null) {
    return startOfMonth(Locale.TODAY)
  }

  if (checkIsSubscriptionExpired(subscription)) {
    return Locale.TODAY
  }

  if (
    subscription.state === 'ACTIVE' &&
    subscription.expirationDate &&
    subscription.pricingPlan.name === 'Trial'
  ) {
    return toDate(subscription.expirationDate)
  }

  return getNextMonth(Locale.TODAY)
}

const SELECTED_CLIENTS_SEARCH_PARAM = 'clients'
const SELECTED_CLIENTS_SEPARATOR = '|'

type SelectedClientsSearchParamsKeyType = 'callback' | 'return'

function getSelectedClientsSearchParamsKey(type: SelectedClientsSearchParamsKeyType) {
  return `clients-${type}`
}

function getSelectedClientsStorageKey(type: SelectedClientsSearchParamsKeyType) {
  const url = new URL(window.location.toString())
  const storageValue = url.searchParams.get(getSelectedClientsSearchParamsKey(type))

  if (storageValue === null) {
    const key = `${StorageKey.SELECTED_CLIENTS}/${Date.now()}`

    url.searchParams.set(getSelectedClientsSearchParamsKey(type), key)
    window.history.replaceState(window.history.state, '', url)

    return key
  }

  return storageValue
}

export function getSelectedClientsStorageSearchParams(
  type: SelectedClientsSearchParamsKeyType
) {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(SELECTED_CLIENTS_SEARCH_PARAM, getSelectedClientsStorageKey(type))

  return searchParams
}

export function getSelectedClientsFromStorage(type: SelectedClientsSearchParamsKeyType) {
  const value = sessionStorage.getItem(getSelectedClientsStorageKey(type))

  if (value === null) {
    return []
  }

  return toTypedArray<CompanyId>(value.split(SELECTED_CLIENTS_SEPARATOR))
}

export function updateSelectedClientsInStorage(
  type: SelectedClientsSearchParamsKeyType,
  clients: CompanyId[]
) {
  sessionStorage.setItem(
    getSelectedClientsStorageKey(type),
    clients.join(SELECTED_CLIENTS_SEPARATOR)
  )
}

export function checkIsLineItemsShown(invoiceDate: string) {
  const showFromDate = ['staging', 'development'].includes(process.env.BUILD_MODE)
    ? '2024-07-30'
    : '2024-08-31'

  return new Date(invoiceDate) > new Date(showFromDate)
}

export const checkIsPracticePayer = (company: Company, practice: Company | undefined) =>
  match(getPayerType(practice?.id, company.subscription))
    .with(P.union('PRACTICE', 'OTHER'), () => true)
    .with(P.union('COMPANY', 'NONE'), () => false)
    .exhaustive()

export const getSortedSubscriptionAddons = (addons: SubscriptionAddon[]) =>
  addons
    .slice()
    .sort((a, b) =>
      a.startDate === null || b.startDate === null
        ? 0
        : a.startDate.localeCompare(b.startDate, undefined, { numeric: true })
    )

export const getPaymentsFreeTrialSubscriptionAddon = (
  subscription: CompanySubscription
) =>
  getSortedSubscriptionAddons(subscription.addons).find(
    (addon): addon is ActiveSubscriptionAddon | EndedSubscriptionAddon =>
      addon.product === 'SUPPLIER_UNLIMITED' &&
      (addon.state === 'ACTIVE' || addon.state === 'ENDED')
  )

export function checkIsSubscriptionEligibleForPaymentsFreeTrial(
  subscription: CompanySubscription | null
): subscription is ActiveSubscription {
  if (subscription === null) {
    return false
  }

  switch (subscription.state) {
    case 'ACTIVE':
      switch (subscription.pricingPlan.name) {
        case 'Go':
        case 'Starter':
        case 'Pitch the switch':
          return true
        default:
          return false
      }
    case 'TERMINATED':
      return false
    default:
      throw new ImpossibleError('Unhandled subscription', subscription)
  }
}

export function checkIsCompanyEligibleForPaymentsFreeTrial(
  subscription: CompanySubscription | null,
  practice: Company | undefined,
  paymentStats: PaymentStats
): subscription is ActiveSubscription {
  return (
    // Business owners (not Accountants/Bookkeepers)
    practice === undefined &&
    // Completed intro trial & starter plan user
    checkIsSubscriptionEligibleForPaymentsFreeTrial(subscription) &&
    // Company account has not settled payrun
    !checkHasPayments(paymentStats)
  )
}

export function getSubscriptionAddonDays(addon: SubscriptionAddon) {
  const startDate = toDate(addon.startDate)
  const endDate = toDate(addon.endDate)

  return diffDays(endDate, startDate)
}

export const checkHasPaymentsFreeTrialAddon = (
  subscription: CompanySubscription | null
) =>
  subscription !== null &&
  getPaymentsFreeTrialSubscriptionAddon(subscription) !== undefined

export type SubscriptionState =
  | { type: 'none' }
  | { type: 'switching-payers'; subscription: ActiveSubscription }
  | {
      type: 'switching-plan'
      subscription: ActiveSubscription | TerminatedSubscription
      nextSubscription: ActiveSubscription
    }
  | { type: 'active'; subscription: ActiveSubscription }
  | { type: 'expiring'; subscription: ActiveSubscription; daysLeft: number }
  | { type: 'expired'; subscription: ActiveSubscription | TerminatedSubscription }

export function getSubscriptionState(
  companyId: CompanyId,
  subscriptions: Subscription[]
): SubscriptionState {
  const [active, next] = getCompanyActiveAndNextSubscriptions(companyId, subscriptions)
  const expired = getCompanyExpiredSubscription(companyId, subscriptions)
  const current = active || expired

  if (current === undefined || current.id === null) {
    return { type: 'none' }
  }

  if (next && current.pricingPlan.id !== next.pricingPlan.id) {
    return { type: 'switching-plan', subscription: current, nextSubscription: next }
  }

  if (next && current.payerId !== next.payerId) {
    return { type: 'switching-payers', subscription: next }
  }

  if (current.pricingPlan.name === 'Free' && current.state === 'TERMINATED') {
    return { type: 'none' }
  }

  if (checkIsSubscriptionExpired(current)) {
    return { type: 'expired', subscription: current }
  }

  if (current.expirationDate) {
    const daysLeft = diffDays(toDate(current.expirationDate), Locale.TODAY)
    return { type: 'expiring', subscription: current, daysLeft }
  }

  return { type: 'active', subscription: current }
}

export type SubscriptionBillingState =
  | { type: 'company-restricted' }
  | { type: 'payment-failed' }
  | SubscriptionState

export function getSubscriptionBillingState(
  company: Company,
  invoices: BillingInvoice[],
  subscriptions: Subscription[]
): SubscriptionBillingState {
  if (company.billingStatus === 'SUSPENDED') {
    return { type: 'company-restricted' }
  }

  const hasFailedPayments = invoices.some((invoice) => invoice.state === 'FAILED')

  if (hasFailedPayments) {
    return { type: 'payment-failed' }
  }

  return getSubscriptionState(company.id, subscriptions)
}

export function getSubscriptionStateLabel(state: SubscriptionState) {
  const intl = getIntl()

  return match(state)
    .with({ type: 'expired', subscription: { pricingPlan: { name: 'Trial' } } }, () =>
      intl.formatMessage({
        id: 'billing.trial.ended',
        defaultMessage: 'Trial ended',
      })
    )
    .with({ type: 'expiring', subscription: { pricingPlan: { name: 'Trial' } } }, () =>
      intl.formatMessage({
        id: 'billing.trial.active',
        defaultMessage: 'Free trial',
      })
    )
    .with({ type: P.union('none', 'expired') }, () =>
      intl.formatMessage({
        id: 'billing.no-active-plan',
        defaultMessage: 'No active plan',
      })
    )
    .with(
      { type: P.union('active', 'expiring', 'switching-payers', 'switching-plan') },
      ({ subscription }) => subscription.pricingPlan.name
    )
    .exhaustive()
}

export function getSubscriptionStateMessage(state: SubscriptionState) {
  const intl = getIntl()
  const dateFormat = getDateFormat()
  const deadlineFormat = getDateFormat('short', { timeZone: 'UTC' })
  const relativeDayFormat = getRelativeDayFormat()

  return match(state)
    .with({ type: 'none' }, () => null)
    .with({ type: 'switching-payers' }, () => null)
    .with({ type: 'switching-plan' }, ({ nextSubscription }) =>
      intl.formatMessage(
        {
          id: 'billing.switching-plan',
          defaultMessage: '{planName} plan starts {day}.',
        },
        {
          planName: nextSubscription.pricingPlan.name,
          day: ensure(relativeDayFormat(nextSubscription.startDate)),
        }
      )
    )
    .with(
      { type: 'expiring', subscription: { pricingPlan: { name: 'Trial' } } },
      ({ daysLeft }) =>
        intl.formatMessage(
          {
            id: 'billing.trial-days-left',
            defaultMessage: `{days, plural,
              one {You have # day left of your free trial.}
              other {You have # days left of your free trial.}
            }`,
          },
          { days: daysLeft }
        )
    )
    .with({ type: 'expiring' }, ({ subscription }) =>
      intl.formatMessage(
        {
          id: 'billing.subscription-expiring',
          defaultMessage: `Subscription ends on {date}.`,
        },
        { date: deadlineFormat.format(subscription.expirationDate) }
      )
    )
    .with(
      { type: 'expired', subscription: { pricingPlan: { name: 'Trial' } } },
      ({ subscription }) =>
        intl.formatMessage(
          {
            id: 'billing.trial-expired',
            defaultMessage: 'Your free trial expired on {date}.',
          },
          { date: dateFormat.format(subscription.endDate || subscription.expirationDate) }
        )
    )
    .with({ type: 'expired' }, ({ subscription }) =>
      intl.formatMessage(
        {
          id: 'billing.subscription-expired',
          defaultMessage: 'Your last subscription ended on {date}.',
        },
        { date: dateFormat.format(subscription.endDate || subscription.expirationDate) }
      )
    )
    .with({ type: 'active' }, ({ subscription }) => {
      const paymentsFreeTrialAddon = getPaymentsFreeTrialSubscriptionAddon(subscription)

      if (paymentsFreeTrialAddon) {
        const daysLeft = diffDays(toDate(paymentsFreeTrialAddon.endDate), Locale.TODAY)

        if (daysLeft < 0 || paymentsFreeTrialAddon.state === 'ENDED') {
          return null
        }

        return intl.formatMessage(
          {
            id: 'billing.payments-free-trial',
            defaultMessage: `You have {days, plural, one {# day} other {# days}} left in your unlimited free payments trial. Make payroll and supplier payments without limits.`,
          },
          { days: daysLeft }
        )
      }

      return null
    })
    .exhaustive()
}

export function getChooseSubscriptionPlanMessage(
  state: SubscriptionState,
  practice: Company | undefined,
  permissions: UserPermission[]
) {
  const intl = getIntl()

  return match(state)
    .with({ type: P.union('none', 'expired') }, (data) => {
      if (!permissions.includes('SUBSCRIPTIONS_EDIT')) {
        return intl.formatMessage({
          id: 'billing.choose-plan.no-access',
          defaultMessage: `Please ask one of admins to choose a plan to unlock invoice capture and payments.`,
        })
      }

      if (
        data.type === 'none' ||
        data.subscription.payerId === data.subscription.subscriberId ||
        data.subscription.payerId === practice?.id
      ) {
        return intl.formatMessage({
          id: 'billing.choose-plan.company-payer',
          defaultMessage: `Choose one of our plans to unlock invoice capture and payments.`,
        })
      } else {
        return intl.formatMessage({
          id: 'billing.choose-plan.practice-payer',
          defaultMessage: `Please ask your Practice admin to choose a plan or move billing account to you.`,
        })
      }
    })
    .with({ type: 'expiring', subscription: { pricingPlan: { name: 'Trial' } } }, () =>
      intl.formatMessage({
        id: 'billing.trial-expiring',
        defaultMessage: `It’s time to choose your plan.`,
      })
    )
    .with({ type: 'active', subscription: { pricingPlan: { name: 'Free' } } }, () =>
      intl.formatMessage({
        id: 'billing.unlimited-capture-payments',
        defaultMessage: `Capture invoices and make payments without limits.`,
      })
    )
    .with(
      { type: P.union('active', 'expiring', 'switching-payers', 'switching-plan') },
      () => null
    )
    .exhaustive()
}

export function getSubscriptionBillingStateMessage(
  company: Company,
  state: SubscriptionBillingState
) {
  const intl = getIntl()

  return match(state)
    .with({ type: 'active', subscription: { pricingPlan: { name: 'Free' } } }, () =>
      company.type === 'PRACTICE'
        ? intl.formatMessage({
            id: 'billing.subscription-free-for-practice',
            defaultMessage: 'Apron is free for your practice.',
          })
        : intl.formatMessage({
            id: 'billing.subscription-free-for-company',
            defaultMessage: 'Apron is free for your company.',
          })
    )
    .with({ type: 'payment-failed' }, () =>
      intl.formatMessage({
        id: 'billing.subscription-payment-failed',
        defaultMessage: 'Subscription payment failed.',
      })
    )
    .with({ type: 'company-restricted' }, () =>
      intl.formatMessage({
        id: 'billing.subscription-account-restricted',
        defaultMessage: 'Your account is restricted.',
      })
    )
    .with(
      { type: 'expiring', subscription: { pricingPlan: { name: 'Trial' } } },
      ({ daysLeft }) =>
        intl.formatMessage(
          {
            id: 'billing.trial-days-left',
            defaultMessage: `{days, plural,
              one {You have # day left of your free trial.}
              other {You have # days left of your free trial.}
            }`,
          },
          { days: daysLeft }
        )
    )
    .with(
      {
        type: P.union(
          'none',
          'active',
          'expiring',
          'expired',
          'switching-payers',
          'switching-plan'
        ),
      },
      () => null
    )
    .exhaustive()
}

export function getUpdateBillingMessage(
  state: SubscriptionBillingState,
  company: Company,
  practice: Company | undefined,
  permissions: UserPermission[]
) {
  const intl = getIntl()
  const payerId = company.subscription ? company.subscription.payerId : company.id

  return match(state)
    .with({ type: P.union('company-restricted', 'payment-failed') }, () => {
      if (!permissions.includes('SUBSCRIPTIONS_EDIT')) {
        return intl.formatMessage({
          id: 'billing.update-billing.no-access',
          defaultMessage: `Please ask one of your admins to update your payment method.`,
        })
      }

      if (payerId === company.id || payerId === practice?.id) {
        return intl.formatMessage({
          id: 'billing.update-billing.company-payer',
          defaultMessage: `Please try again or update your payment method.`,
        })
      } else {
        return intl.formatMessage({
          id: 'billing.update-billing.practice-payer',
          defaultMessage: `Please ask one of your Practice admins to update your payment method.`,
        })
      }
    })
    .with(
      {
        type: P.union(
          'active',
          'expired',
          'expiring',
          'none',
          'switching-payers',
          'switching-plan'
        ),
      },
      () => null
    )
    .exhaustive()
}

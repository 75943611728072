import { setRefs } from 'kitchen/utils/helpers'
import { useRef, useEffect } from 'react'
import * as Icons from '../../icons'
import { HStack, Text, ZStack, Grid, CheckboxBase } from '../../primitives'
import type { TextProps, StackProps, CheckboxBaseProps } from '../../primitives'
import { styled, theme } from '../../stitches'

const CheckboxLabel = styled(HStack, {
  alignItems: 'center',
  width: 'fit-content',
  cursor: 'pointer',
  '&[data-disabled="true"]': {
    cursor: 'default',
    color: theme.colors['black-alpha-30'],
  },
})

export interface CheckboxInputProps extends CheckboxBaseProps {
  indeterminate?: boolean
  children?: React.ReactNode
}

const CheckboxInput = ({
  children,
  checked,
  indeterminate = false,
  ref = null,
  ...props
}: CheckboxInputProps) => {
  const elementRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (elementRef.current !== null) {
      elementRef.current.indeterminate = indeterminate
    }
  }, [elementRef, indeterminate])

  return (
    <ZStack css={{ size: 'max-content' }}>
      <CheckboxBase ref={setRefs(elementRef, ref)} checked={checked} {...props} />
      <Grid css={{ placeContent: 'center' }} data-checkbox-indicator>
        {children ?? (indeterminate ? <Icons.S16.Minus /> : <Icons.S16.Check />)}
      </Grid>
    </ZStack>
  )
}

const CheckboxWithLabel = (props: StackProps) => {
  return <CheckboxLabel as="label" gap={8} {...props} />
}

const Label = (props: TextProps) => {
  return <Text variant="title-16" {...props} />
}

export { CheckboxWithLabel as Root, Label, CheckboxInput as Input }

import type { GetStartedTask } from '../types'

export function getTasksProgress(tasks: GetStartedTask[]) {
  const completed = tasks.filter((task) => task.done)
  return completed.length / tasks.length
}

export function checkIsTasksDone(tasks: GetStartedTask[]) {
  return tasks.every((task) => task.done)
}

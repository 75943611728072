const rotation = {
  left: 0,
  right: 180,
  top: 90,
  bottom: 270,
} as const

interface ChevronProps extends React.SVGAttributes<SVGElement> {
  variant?: keyof typeof rotation
}

export const Chevron = ({ variant = 'left', style, ...rest }: ChevronProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={{ transform: `rotate(${rotation[variant]}deg)`, ...style }}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.79289 0.792892L11.2071 2.20711L5.41421 8L11.2071 13.7929L9.79289 15.2071L2.5 8L9.79289 0.792892Z"
      fill="currentColor"
    />
  </svg>
)

import type { RegisteredUser, User } from 'api/types/users'
import { PhoneInput, PhoneCountryCallingCodeSelect } from 'domains/phone/components'
import { PHONE_COUNTRY_CALLING_CODES } from 'domains/phone/constants'
import { Controller, useForm, yupResolver } from 'kitchen/forms'
import { Button, Input, InputGroup, Label } from 'salad/components'
import { HStack, VStack } from 'salad/primitives'
import type { FormValues } from './types'
import { getDefaultValues, getValidationSchema } from './utils'

interface UpdateUserDetailsFormProps {
  user: RegisteredUser | User
  isLoading: boolean
  onSubmit: (formValues: FormValues) => void
  onCancel: () => void
}

export function UpdateUserDetailsForm({
  user,
  isLoading,
  onSubmit,
  onCancel,
}: UpdateUserDetailsFormProps) {
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: getDefaultValues(user, PHONE_COUNTRY_CALLING_CODES),
    resolver: yupResolver(getValidationSchema()),
    mode: 'onSubmit',
  })

  return (
    <VStack
      gap={32}
      as="form"
      onSubmit={handleSubmit((formValues) => {
        onSubmit(formValues)
      })}
    >
      <InputGroup.Root>
        <InputGroup.Label>First name</InputGroup.Label>
        <Input {...register('firstName')} disabled={isLoading} />
      </InputGroup.Root>
      <InputGroup.Root>
        <InputGroup.Label>Last name</InputGroup.Label>
        <Input {...register('lastName')} disabled={isLoading} />
      </InputGroup.Root>
      <VStack gap={8}>
        <Label>Phone number (optional)</Label>
        <HStack
          css={{ justifyContent: 'space-between', gridAutoColumns: '1fr 4fr' }}
          gap={24}
        >
          <Controller
            name="phoneCountryCallingCode"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const hasErrors = error !== undefined

              return (
                <InputGroup.Root>
                  <PhoneCountryCallingCodeSelect
                    {...field}
                    options={PHONE_COUNTRY_CALLING_CODES}
                    aria-invalid={hasErrors}
                  />
                </InputGroup.Root>
              )
            }}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const hasErrors = error !== undefined

              return (
                <InputGroup.Root>
                  <PhoneInput
                    {...field}
                    phoneCountryCallingCode={watch('phoneCountryCallingCode')}
                    aria-invalid={hasErrors}
                  />
                </InputGroup.Root>
              )
            }}
          />
        </HStack>
        <InputGroup.Message
          variant={errors.phoneCountryCallingCode ? 'negative' : 'neutral'}
        >
          {errors.phoneCountryCallingCode?.message || 'For WhatsApp invoice submission'}
        </InputGroup.Message>
      </VStack>
      <HStack gap={8} css={{ gridTemplateColumns: 'min-content 1fr' }}>
        <Button.Root variant="minor" onClick={onCancel} disabled={isLoading}>
          <Button.Content>Cancel</Button.Content>
        </Button.Root>
        <Button.Root type="submit" variant="common" loading={isLoading}>
          <Button.Content>Save</Button.Content>
        </Button.Root>
      </HStack>
    </VStack>
  )
}

import { OPENREPLAY_EXCLUDE_CLASS, OPENREPLAY_MASK_CLASS } from 'kitchen/constants'
import { Slot } from 'radix-ui'
import type React from 'react'

interface SensitiveProps {
  children: React.ReactNode
  behavior?: 'mask' | 'exclude' | 'ignore'
}

const getClassName = (behavior: 'mask' | 'exclude') => {
  switch (behavior) {
    case 'exclude':
      return OPENREPLAY_EXCLUDE_CLASS
    case 'mask':
      return OPENREPLAY_MASK_CLASS
    default:
      return undefined
  }
}

function Sensitive({ children, behavior = 'mask' }: SensitiveProps) {
  switch (behavior) {
    case 'ignore':
      return children
    default:
      return <Slot.Root className={getClassName(behavior)}>{children}</Slot.Root>
  }
}

export { Sensitive }

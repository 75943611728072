import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import type { User } from 'api/types/users'
import { CacheKey } from 'kitchen/constants'
import { formatName } from 'kitchen/utils/helpers'
import { Portal } from 'radix-ui'
import { memo, useEffect, useRef } from 'react'
import { Button } from 'salad/components'
import { theme } from 'salad/stitches'
import { BugIcon } from './bug-icon'

type BugReportForm = Awaited<
  ReturnType<ReturnType<typeof Sentry.feedbackIntegration>['createForm']>
>

export const BugReport = memo(function BugReport() {
  const bugReportForm = useRef<BugReportForm>(undefined)

  const queryClient = useQueryClient()

  useEffect(() => {
    const feedbackIntegration = Sentry.feedbackIntegration({
      autoInject: false,
      showName: false,
      showEmail: false,
      themeLight: {
        pageMargin: 50,
      },
      colorScheme: 'light',
      onFormClose: () => {
        bugReportForm.current?.close()
      },
      onSubmitSuccess: (data: Record<string, string>) => {
        const user: User | undefined = queryClient.getQueryData([CacheKey.CURRENT_USER])
        const queryKeys = queryClient
          .getQueryCache()
          .getAll()
          .filter(
            (cache) =>
              !cache.state.isInvalidated &&
              cache.queryKey.length > 1 &&
              cache.queryKey[0] !== CacheKey.FEATURES
          )
          .map((cache) => cache.queryKey)

        Sentry.sendFeedback({
          ...data,
          tags: {
            hidden: true,
          },
          message: [
            `Message:\n${data.message}`,
            `User id: ${user?.id}`,
            `Query Keys:\n${queryKeys.map((key) => JSON.stringify(key)).join(';\n')}`,
          ].join('\n\n'),
          name: user ? formatName(user.firstName, user.lastName) : undefined,
          email: user?.email,
        })
      },
    })
    Sentry.addIntegration(feedbackIntegration)

    feedbackIntegration
      .createForm()
      .then((form) => {
        form.appendToDom()
        bugReportForm.current = form
        return
      })
      .catch(() => null)

    return () => {
      bugReportForm.current?.removeFromDom()
    }
  }, [])

  return (
    <Portal.Root>
      <Button.Root
        id="bug-report-button"
        onClick={() => {
          bugReportForm.current?.open()
        }}
        css={{
          width: 48,
          height: 48,
          position: 'fixed',
          backdropFilter: 'blur(2px)',
          right: 0,
          bottom: theme.space[64],
          margin: theme.space[20],
          zIndex: 9999,
          pointerEvents: 'auto',
        }}
        variant="minor"
        size="icon"
        radius="round"
        hug
      >
        <Button.Content css={{ pointerEvents: 'none' }}>
          <BugIcon />
        </Button.Content>
      </Button.Root>
    </Portal.Root>
  )
})

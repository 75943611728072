import type { GenericComponent } from 'kitchen/types'
import * as Dialog from '../dialog'
import type { DialogContentProps } from '../dialog'
import * as Status from '../status'
import type {
  StatusActionProps,
  StatusDescriptionProps,
  StatusTitleProps,
} from '../status'
import { Surface } from '../surface'

export interface AlertDialogProps extends Dialog.DialogProps {}

const AlertDialogIcon = Status.Icon
const AlertDialogRoot = Dialog.Root
const AlertDialogClose = Dialog.Close
const AlertDialogTrigger = Dialog.Trigger
const useAlertDialogContext = Dialog.useContext

const AlertDialogTitle = (props: StatusTitleProps) => (
  <Dialog.Title asChild>
    <Status.Title {...props} />
  </Dialog.Title>
)

const AlertDialogDescription = (props: StatusDescriptionProps) => (
  <Dialog.Description asChild>
    <Status.Description {...props} />
  </Dialog.Description>
)

const AlertDialogContent = ({
  center = true,
  closable,
  children,
  onPointerDownOutside,
  onInteractOutside,
  ...rest
}: DialogContentProps) => {
  const context = useAlertDialogContext()
  return (
    <Dialog.Content
      role="alertdialog"
      variant={448}
      center={center}
      closable={closable}
      onPointerDownOutside={(event) => {
        onPointerDownOutside?.(event)
        if (event.defaultPrevented || closable) {
          return
        }

        event.preventDefault()
      }}
      onInteractOutside={(event) => {
        onInteractOutside?.(event)
        if (event.defaultPrevented || closable) {
          return
        }

        event.preventDefault()
      }}
      {...rest}
    >
      <Surface variant="flat" p="32">
        <Status.Root variant="dialog">
          {typeof children === 'function' ? children(context) : children}
        </Status.Root>
      </Surface>
    </Dialog.Content>
  )
}

interface AlertDialogActionProps extends StatusActionProps {
  autoClose?: boolean
}

const AlertDialogAction: GenericComponent<AlertDialogActionProps> = ({
  autoClose = false,
  ...statusActionProps
}) => {
  if (autoClose) {
    return (
      <Dialog.Close asChild>
        <Status.Action variant="destructive" {...statusActionProps} />
      </Dialog.Close>
    )
  }

  return <Status.Action variant="destructive" {...statusActionProps} />
}

const AlertDialogCancel: GenericComponent<StatusActionProps> = (props) => (
  <Dialog.Close asChild>
    <Status.Action variant="minor" {...props} />
  </Dialog.Close>
)

export {
  AlertDialogRoot as Root,
  AlertDialogContent as Content,
  AlertDialogIcon as Icon,
  AlertDialogTitle as Title,
  AlertDialogDescription as Description,
  AlertDialogAction as Action,
  AlertDialogCancel as Cancel,
  AlertDialogClose as Close,
  AlertDialogTrigger as Trigger,
  useAlertDialogContext as useContext,
}

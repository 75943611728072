import type {
  UserRole,
  UserPermission,
  EveryAdminPermission,
  EveryBookkeepingPermission,
  EveryInvoicePaymentPermission,
  EveryPayrollPaymentPermission,
  EveryManualPaymentPermission,
  EveryPaymentPermission,
  EveryManagePermission,
  EveryCommentPermission,
  EveryManageWorkflowPermission,
  EveryCommonPermission,
  EveryPermission,
  EveryCommonAdminPermission,
} from 'api/types/permissions'

export const USER_ROLES = [
  'ADMIN',
  'PAYER',
  'APPROVER',
  'CREATOR',
  'CUSTOM',
  'BOOKKEEPER',
  'EMPLOYEE',
] as const satisfies UserRole[]

export const INVOICE_PAYMENT_PERMISSIONS = [
  'INVOICE_PAYMENTS_VIEW',
  'INVOICE_PAYMENTS_ASSIGNED_VIEW',
  'INVOICE_PAYMENTS_CREATE',
  'INVOICE_PAYMENTS_EDIT',
  'INVOICE_PAYMENTS_APPROVE',
  'INVOICE_PAYMENTS_AUTHORISE',
] satisfies EveryInvoicePaymentPermission

export const PAYROLL_PAYMENT_PERMISSIONS = [
  'PAYROLL_PAYMENTS_VIEW',
  'PAYROLL_PAYMENTS_ASSIGNED_VIEW',
  'PAYROLL_PAYMENTS_CREATE',
  'PAYROLL_PAYMENTS_EDIT',
  'PAYROLL_PAYMENTS_APPROVE',
  'PAYROLL_PAYMENTS_AUTHORISE',
] satisfies EveryPayrollPaymentPermission

export const MANUAL_PAYMENT_PERMISSIONS = [
  'MANUAL_PAYMENTS_VIEW',
  'MANUAL_PAYMENTS_ASSIGNED_VIEW',
  'MANUAL_PAYMENTS_CREATE',
  'MANUAL_PAYMENTS_EDIT',
  'MANUAL_PAYMENTS_APPROVE',
  'MANUAL_PAYMENTS_AUTHORISE',
] satisfies EveryManualPaymentPermission

export const PAYMENT_PERMISSIONS = [
  ...INVOICE_PAYMENT_PERMISSIONS,
  ...PAYROLL_PAYMENT_PERMISSIONS,
  ...MANUAL_PAYMENT_PERMISSIONS,
] satisfies EveryPaymentPermission

export const MANAGE_PERMISSIONS = [
  'USERS_EDIT',
  'SUBSCRIPTIONS_EDIT',
  'COMPANY_EDIT',
] satisfies EveryManagePermission

export const ADMIN_PERMISSIONS = [
  'APPROVALS_EDIT',
  ...MANAGE_PERMISSIONS,
] satisfies EveryAdminPermission

export const MANAGE_WORKFLOWS_PERMISSIONS = [
  'APPROVALS_EDIT',
] satisfies EveryManageWorkflowPermission

export const BOOKKEEPING_PERMISSIONS = [
  'BOOKKEEPING_VIEW',
  'BOOKKEEPING_ASSIGNED_VIEW',
  'BOOKKEEPING_PUBLISH',
] satisfies EveryBookkeepingPermission

export const COMMENTS_PERMISSIONS = [
  'COMMENTS_VIEW',
  'COMMENTS_EDIT',
] satisfies EveryCommentPermission

export const COMMON_PERMISSIONS = [
  'USERS_VIEW',
  'APPROVALS_VIEW',
  'SUBSCRIPTIONS_VIEW',
] satisfies EveryCommonPermission

export const COMMON_ADMIN_PERMISSIONS = [
  'CARDS_ADMIN',
  'WALLETS_ADMIN',
] satisfies EveryCommonAdminPermission

export const PERMISSIONS = [
  ...PAYMENT_PERMISSIONS,
  ...BOOKKEEPING_PERMISSIONS,
  ...COMMENTS_PERMISSIONS,
  ...ADMIN_PERMISSIONS,
] satisfies EveryPermission

export const FULL_BOOKKEEPING_PERMISSIONS = exclude(
  BOOKKEEPING_PERMISSIONS,
  'ASSIGNED_VIEW'
)

export const FULL_INVOICE_PAYMENT_PERMISSIONS = exclude(
  INVOICE_PAYMENT_PERMISSIONS,
  'ASSIGNED_VIEW'
)

export const FULL_PAYROLL_PAYMENT_PERMISSIONS = exclude(
  PAYROLL_PAYMENT_PERMISSIONS,
  'ASSIGNED_VIEW'
)

export const FULL_MANUAL_PAYMENT_PERMISSIONS = exclude(
  MANUAL_PAYMENT_PERMISSIONS,
  'ASSIGNED_VIEW'
)

export const FULL_PAYMENT_PERMISSIONS = exclude(PAYMENT_PERMISSIONS, 'ASSIGNED_VIEW')
export const FULL_ADMIN_PERMISSIONS = exclude(PERMISSIONS, 'ASSIGNED_VIEW')

export const OWNER_PERMISSIONS = [
  ...COMMENTS_PERMISSIONS,
  ...FULL_INVOICE_PAYMENT_PERMISSIONS,
  ...FULL_MANUAL_PAYMENT_PERMISSIONS,
  ...FULL_PAYROLL_PAYMENT_PERMISSIONS,
  ...ADMIN_PERMISSIONS,
  ...COMMON_PERMISSIONS,
  ...FULL_BOOKKEEPING_PERMISSIONS,
] satisfies UserPermission[]

/** @private Don't use outside module */
function exclude<I extends UserPermission, T extends string>(input: I[], suffix: T) {
  return input.filter(
    (permission): permission is Exclude<I, `${string}_${T}`> =>
      !permission.endsWith(suffix)
  )
}

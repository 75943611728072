import { Box } from '../../primitives'
import { styled, theme } from '../../stitches'

const ShadowWrapper = styled(Box, {
  filter: 'drop-shadow(0px 18.772px 37.543px rgba(93, 56, 0, 0.10))',
})

const PhotoFrameWrapper = styled(Box, {
  position: 'relative',
  display: 'inline-block',
  transform: 'translateZ(0)', // fix clip-path artifact
  backgroundColor: theme.colors.white,

  '&::after': {
    // frame
    content: '',
    position: 'absolute',
    inset: '-1px',
    borderStyle: 'solid',
    borderColor: theme.colors['white'],
  },

  variants: {
    frameWidth: {
      small: {
        $$dogEarSize: '50px',
        '&::after': { borderWidth: 8 },
      },
      medium: {
        $$dogEarSize: '50px',
        '@bp3': {
          $$dogEarSize: '80px',
        },
        '&::after': {
          borderWidth: 10,
          '@bp3': {
            borderWidth: 16,
          },
        },
      },
    },
    dogEarSide: {
      'top-right': {
        clipPath:
          'polygon(0% 0%, calc(100% - $$dogEarSize) 0, 100% $$dogEarSize, 100% 100%, 0% 100%)',

        '&:before': {
          // dogEar
          content: '',
          top: '-1px',
          right: '-1px',
          position: 'absolute',
          width: '$$dogEarSize',
          height: '$$dogEarSize',
          backgroundImage: 'linear-gradient(to top right, white 50%, transparent 0)',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left, right',
        },
      },
      'bottom-right': {
        clipPath:
          'polygon(0% 0%, 100% 0, 100% calc(100% - $$dogEarSize), calc(100% - $$dogEarSize) 100%, 0% 100%)',
        '&:before': {
          // dogEar
          content: '',
          bottom: '-1px',
          right: '-1px',
          transform: 'rotate(180deg)',
          position: 'absolute',
          width: '$$dogEarSize',
          height: '$$dogEarSize',
          backgroundImage: 'linear-gradient(to bottom right, transparent 50%, white 0)',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left, right',
        },
      },
    },
  },
  defaultVariants: {
    frameWidth: 'medium',
  },
})

interface PhotoFrameProps extends React.ComponentProps<typeof PhotoFrameWrapper> {}

export const PhotoFrame = ({ css, className, ...rest }: PhotoFrameProps) => {
  return (
    <ShadowWrapper css={css} className={className}>
      <PhotoFrameWrapper {...rest} />
    </ShadowWrapper>
  )
}

import { Input } from 'salad/components'
import { usePhoneRifm } from '../../hooks'
import type { PhoneCountryCallingCode } from '../../types'

interface PhoneInputProps {
  phoneCountryCallingCode: PhoneCountryCallingCode | null
  value: string
  onChange: (value: string) => void
}

export function PhoneInput({
  phoneCountryCallingCode,
  value,
  onChange,
  ...rest
}: PhoneInputProps) {
  const rifm = usePhoneRifm({
    phoneCountryCallingCode,
    value,
    onChange,
  })

  return <Input inputMode="numeric" {...rifm} {...rest} />
}

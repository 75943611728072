import { ensure } from 'kitchen/utils/helpers'
import { FormattedMessage } from 'react-intl'
import { Surface, Status, AnimatedSuccessIcon, Confetti } from 'salad/components'
import type { CompanySubscription } from '../../types'
import {
  getPaymentsFreeTrialSubscriptionAddon,
  getSubscriptionAddonDays,
} from '../../utils'

interface PaymentsFreeTrialConfirmationProps {
  subscription: CompanySubscription
  onDone: () => void
}

export function PaymentsFreeTrialConfirmation({
  subscription,
  onDone,
}: PaymentsFreeTrialConfirmationProps) {
  return (
    <Surface p={32} style={{ maxWidth: 448, marginInline: 'auto' }}>
      <Status.Root variant="dialog">
        <Status.Icon>
          <Confetti variant="cannon" size={64}>
            <AnimatedSuccessIcon size={64} />
          </Confetti>
        </Status.Icon>
        <Status.Title>
          <FormattedMessage
            id="billing.payments-free-trial-flow.confirmation.title"
            defaultMessage="Success! Your trial starts today"
          />
        </Status.Title>
        <Status.Description>
          <FormattedMessage
            id="billing.payments-free-trial-flow.confirmation.description"
            defaultMessage="Your company can make unlimited payments for {days} days. Once your trial ends continue making payments on your current plan or upgrade."
            values={{
              days: getSubscriptionAddonDays(
                ensure(getPaymentsFreeTrialSubscriptionAddon(subscription))
              ),
            }}
          />
        </Status.Description>
        <Status.Action variant="common" onClick={() => onDone()}>
          <FormattedMessage id="common.acknowledge" defaultMessage="Got it" />
        </Status.Action>
      </Status.Root>
    </Surface>
  )
}

import { useControlled } from 'kitchen/hooks/use-controlled'
import { useContext } from 'react'
import { InputGroupContext } from '../../context/input-group-context'
import { type InputBaseProps, InputBase } from '../../primitives'
import { styled } from '../../stitches'

const AutosizeInputGrid = styled('span', {
  display: 'grid',
  gridTemplate: `'input'`,
  width: 'fit-content',
  maxWidth: '100%',
  'input, [data-autosize-spacer]': {
    gridArea: 'input',
  },
})

const AutosizeInputSpacer = styled('span', {
  width: 'auto',
  height: 0,
  overflow: 'hidden',
  visibility: 'hidden',
  whiteSpace: 'pre',
  '& + input': { width: '100%' },
})

const AutosizeInput = ({
  defaultValue = '',
  value,
  onChange,
  placeholder,
  ...rest
}: InputBaseProps) => {
  const [replicatedValue, setReplicatedValue] = useControlled({
    defaultValue,
    value,
  })

  return (
    <>
      <AutosizeInputSpacer data-autosize-spacer aria-hidden>
        {replicatedValue || placeholder}
      </AutosizeInputSpacer>
      <InputBase
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          setReplicatedValue(event.currentTarget.value)
          onChange?.(event)
        }}
        size={1}
        {...rest}
      />
    </>
  )
}

export interface InputProps extends InputBaseProps {
  autosize?: boolean
}

export const Input = ({ autosize, ...rest }: InputProps) => {
  const context = useContext(InputGroupContext)
  const isInsideInputGroup = context !== null

  if (isInsideInputGroup) {
    return autosize ? (
      <AutosizeInput {...context.getAriaProps(rest)} {...rest} />
    ) : (
      <InputBase {...context.getAriaProps(rest)} {...rest} />
    )
  }

  return autosize ? (
    <AutosizeInputGrid>
      <AutosizeInput {...rest} />
    </AutosizeInputGrid>
  ) : (
    <InputBase {...rest} />
  )
}

import { useSearchParams } from 'react-router-dom'

export * from './active-router-link'
export * from './hooks'
export * from './matches-patterns'
export * from './router-provider'
export * from './use-location'
export * from './use-return-path'
export * from './utils'
export type { Location, Path as RouterPath, NavigateOptions, To } from 'react-router-dom'
export {
  Route,
  Routes,
  Navigate,
  MemoryRouter,
  useNavigate,
  useParams,
  generatePath,
  resolvePath,
  Link as RouterLink,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom'
export { useOptimisticSearchParams } from 'nuqs/adapters/react-router'
export { useQueryState, useQueryStates } from 'nuqs'

/** @deprecated use useQueryState or useQueryStates instead */
const deprecatedUseSearchParams = useSearchParams
export { deprecatedUseSearchParams as useSearchParams }

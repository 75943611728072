import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { getSessionId } from 'domains/analytics/utils'
import { IntlProvider } from 'domains/i18n/components'
import { defaultAppLocale } from 'domains/i18n/constants'
import { queryClient, queryCache } from 'kitchen/utils/query-client'
import { Portal } from 'radix-ui'
import { IntercomProvider } from 'react-use-intercom'
import { Tooltip, Toast } from 'salad/components'
import { OpenReplayProvider } from './open-replay'

queryCache.config.onError = (error) => {
  Sentry.captureException(error)
}

interface ProvidersProps {
  children?: React.ReactNode
}

export const AppProviders = ({ children }: ProvidersProps) => (
  <QueryClientProvider client={queryClient}>
    <OpenReplayProvider>
      <IntercomProvider
        appId={process.env.INTERCOM_APP_ID}
        shouldInitialize={!process.env.PLAYWRIGHT}
        autoBoot
        autoBootProps={{
          customAttributes: {
            env: process.env.BUILD_MODE,
            session_id: getSessionId(),
          },
        }}
      >
        <IntlProvider locale={defaultAppLocale} packageName="app">
          <Toast.Provider>
            <Tooltip.Provider>{children}</Tooltip.Provider>
          </Toast.Provider>
        </IntlProvider>
      </IntercomProvider>
      {process.env.BUILD_MODE === 'development' && (
        <Portal.Root>
          <ReactQueryDevtools
            initialIsOpen={false}
            position="bottom-right"
            toggleButtonProps={{
              style: { bottom: 135, right: 14, filter: 'grayscale(0.8)' },
            }}
          />
        </Portal.Root>
      )}
    </OpenReplayProvider>
  </QueryClientProvider>
)

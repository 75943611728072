const HORIZONTAL_ROTATIONS = {
  left: 180,
  right: 0,
} as const

type HorizontalRotationVariant = keyof typeof HORIZONTAL_ROTATIONS

interface CircleArrowProps {
  variant?: HorizontalRotationVariant
}

export const CircleArrow = ({ variant = 'right' }: CircleArrowProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{ transform: `rotate(${HORIZONTAL_ROTATIONS[variant]}deg)` }}
  >
    <path
      d="M12 11V8L16 12L12 16V13H8V11H12ZM12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20Z"
      fill="currentColor"
    />
  </svg>
)

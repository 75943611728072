import { useCompanySettings, useHasPractice } from 'api/hooks/companies'
import type { Company } from 'api/types/companies'
import type { User } from 'api/types/users'
import { usePaymentStats } from 'domains/payment/queries'
import type { PaymentStats } from 'domains/payment/types'
import { usePaymentCycleSetup } from 'domains/payment-cycle/queries'
import { getCompanyUser } from 'kitchen/utils/users'
import { useCallback } from 'react'
import { checkIsTasksDone, getTasks, checkIsCompanyCompletedInviteFlow } from '../utils'

interface HasGetStartedOptions {
  user: User
  company: Company
  suspense?: boolean
}

export function useHasGetStarted({ user, company, suspense }: HasGetStartedOptions) {
  const hasPractice = useHasPractice()
  const companySettings = useCompanySettings({ companyId: company.id }, { suspense })
  const paymentCycleSetup = usePaymentCycleSetup({ companyId: company.id }, { suspense })

  return usePaymentStats(
    { companyId: company.id },
    {
      select: useCallback(
        (stats: PaymentStats) => {
          if (
            !companySettings.isSuccess ||
            !paymentCycleSetup.isSuccess ||
            company.practices.length === 0 ||
            hasPractice
          ) {
            return false
          }

          const isOwner = getCompanyUser(user.id, company)?.preset === 'OWNER'
          const isCompletedInviteFlow = checkIsCompanyCompletedInviteFlow(
            companySettings.data
          )
          const isTasksDone = checkIsTasksDone(
            getTasks(company, companySettings.data, paymentCycleSetup.data, stats)
          )

          return isOwner && isCompletedInviteFlow && !isTasksDone
        },
        [
          hasPractice,
          user.id,
          company,
          companySettings.isSuccess,
          companySettings.data,
          paymentCycleSetup.isSuccess,
          paymentCycleSetup.data,
        ]
      ),
      suspense,
    }
  )
}

import { CompanyIdSchema } from 'domains/company/schemas'
import { DayOfWeekSchema } from 'domains/date/schemas'
import { getIntl } from 'domains/i18n/utils'
import { MoneySchema } from 'domains/money/schemas'
import { UserIdSchema } from 'domains/user/schemas'
import { z } from 'zod'

export const PaymentCycleIdSchema = z.string().uuid().brand<'PaymentCycleId'>()
export const PaymentCycleSetupIdSchema = z.string().uuid().brand<'PaymentCycleSetupId'>()

export const PaymentCyclePlannedDateSchema = z
  .string()
  .date()
  .brand<'PaymentCyclePlannedDate'>()

export const PaymentCycleDueDateRuleSchema = z.enum(['BEFORE', 'AFTER', 'CLOSEST'], {
  errorMap() {
    const { formatMessage } = getIntl()

    return {
      message: formatMessage({
        id: 'payment-cycles.due-date-rule.required',
        defaultMessage: 'Please select an option',
      }),
    }
  },
})
export const PaymentCycleFrequencySchema = z.enum(['WEEKLY', 'BIWEEKLY', 'MONTHLY'])
export const PaymentCycleStateSchema = z.enum(['ACTIVE', 'AGED'])
export const PaymentCycleTypeSchema = z.enum(['SCHEDULED', 'CUSTOM', 'UNPLANNED'])

const PaymentCycleBaseSchema = z.object({
  id: PaymentCycleIdSchema,
  state: PaymentCycleStateSchema,
  plannedDate: PaymentCyclePlannedDateSchema.nullable(),
  totalCountToPay: z.number(),
  totalAmountToPay: MoneySchema,
  isApproximate: z.boolean(),
})

export const PaymentCycleSchema = z.discriminatedUnion('type', [
  PaymentCycleBaseSchema.extend({
    type: z.literal(PaymentCycleTypeSchema.Values.SCHEDULED),
    plannedDate: PaymentCyclePlannedDateSchema,
  }),
  PaymentCycleBaseSchema.extend({
    type: z.literal(PaymentCycleTypeSchema.Values.CUSTOM),
    plannedDate: PaymentCyclePlannedDateSchema,
  }),
  PaymentCycleBaseSchema.extend({
    type: z.literal(PaymentCycleTypeSchema.Values.UNPLANNED),
    plannedDate: z.null(),
  }),
])

export const PaymentCyclesResponseSchema = z.array(PaymentCycleSchema)

export const PaymentCycleSetupBaseSchema = z.object({
  startDate: z.string().date(),
  dueDateRule: PaymentCycleDueDateRuleSchema,
})

export const PaymentCycleScheduleSchema = z.discriminatedUnion(
  'type',
  [
    z.object({
      type: z.literal(PaymentCycleFrequencySchema.Values.MONTHLY),
      dayOfMonth: z.number({
        errorMap() {
          const { formatMessage } = getIntl()

          return {
            message: formatMessage({
              id: 'date.day-of-month.required',
              defaultMessage: 'Please select an option',
            }),
          }
        },
      }),
    }),
    z.object({
      type: z.literal(PaymentCycleFrequencySchema.Values.WEEKLY),
      dayOfWeek: DayOfWeekSchema,
    }),
    z.object({
      type: z.literal(PaymentCycleFrequencySchema.Values.BIWEEKLY),
      dayOfWeek: DayOfWeekSchema,
    }),
  ],
  {
    errorMap() {
      const { formatMessage } = getIntl()

      return {
        message: formatMessage({
          id: 'payment-cycles.payment-schedule.required',
          defaultMessage: 'Please select an option',
        }),
      }
    },
  }
)

export const PaymentCycleSetupSchema = PaymentCycleSetupBaseSchema.and(
  PaymentCycleScheduleSchema
)

export const PaymentCycleSetupResponseSchema = PaymentCycleSetupSchema.or(z.null())

export const PaymentCyclesPayloadSchema = z.object({
  companyId: CompanyIdSchema,
  dueDateTo: z.string().date().nullable().optional(),
  dueDateFrom: z.string().date().nullable().optional(),
  issueDateFrom: z.string().date().nullable().optional(),
  issueDateTo: z.string().date().nullable().optional(),
  exclude: z.enum(['NON_REIMBURSABLE_RECEIPT']).nullable().optional(),
})

export const PaymentCycleSetupSchedulePayloadSchema = z.object({
  companyId: CompanyIdSchema,
  from: z.string().date(),
  limit: z.number(),
})

export const PaymentCycleSetupScheduleResponseSchema = z.object({
  id: PaymentCycleSetupIdSchema,
  type: PaymentCycleFrequencySchema,
  startDate: z.string().date(),
  plannedDates: z.array(PaymentCyclePlannedDateSchema),
})

export const CreatePaymentCyclePayloadSchema = z.object({
  companyId: CompanyIdSchema,
  plannedDate: PaymentCyclePlannedDateSchema,
})

export const CreatePaymentCycleResponseSchema = z.object({
  id: PaymentCycleIdSchema,
})

export const PaymentCyclesNotificationSettingsSchema = z.record(
  UserIdSchema,
  z.boolean(),
  {
    errorMap() {
      return {
        message: getIntl().formatMessage({
          id: 'common.required',
          defaultMessage: 'Required',
        }),
      }
    },
  }
)

export const PaymentCyclesNotificationSettingsPayloadSchema = z.object({
  companyId: CompanyIdSchema,
})

export const UpdatePaymentCyclesNotificationSettingsPayloadSchema = z.object({
  companyId: CompanyIdSchema,
  notifyUsers: PaymentCyclesNotificationSettingsSchema,
})

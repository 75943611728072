import { useAppNotifications } from 'api/hooks/apron'
import { Time } from 'kitchen/constants'
import { Portal } from 'radix-ui'
import { useEffect } from 'react'
import { AlertBanner, AlertDialog } from 'salad/components'
import { VStack, Text } from 'salad/primitives'

export function AppNotifications() {
  const { data: notifications = [] } = useAppNotifications({
    cacheTime: Time.MINUTE * 15,
    staleTime: Time.MINUTE * 15,
  })

  useEffect(() => {
    if (notifications.length > 0) {
      document.documentElement.style.setProperty(
        '--app-header-offset',
        `${notifications.length * 40}px`
      )

      return () => {
        document.documentElement.style.removeProperty('--app-header-offset')
      }
    }
  }, [notifications.length])

  return (
    <Portal.Root>
      {notifications.map((item, index) => (
        <AlertBanner.Root
          key={index}
          variant="neutral"
          css={{ position: 'fixed', top: 0, left: 0, right: 0 }}
        >
          <AlertBanner.Content>{item.shortText}</AlertBanner.Content>
          {item.longTextTitle || item.longText ? (
            <AlertDialog.Root>
              <AlertDialog.Trigger asChild>
                <AlertBanner.Action>Read more</AlertBanner.Action>
              </AlertDialog.Trigger>
              <AlertDialog.Content>
                {item.longTextTitle && (
                  <AlertDialog.Title>{item.longTextTitle}</AlertDialog.Title>
                )}
                {item.longText && (
                  <AlertDialog.Description as="div" align="start">
                    <VStack as="div" gap={8}>
                      {item.longText.split('\n').map((part, index) => (
                        <Text key={index} as="p">
                          {part}
                        </Text>
                      ))}
                    </VStack>
                  </AlertDialog.Description>
                )}
                <AlertDialog.Cancel>Close</AlertDialog.Cancel>
              </AlertDialog.Content>
            </AlertDialog.Root>
          ) : (
            item.link && (
              <AlertBanner.Action
                as="a"
                href={item.link}
                target="_blank"
                rel="noreferrer"
              >
                Read more
              </AlertBanner.Action>
            )
          )}
        </AlertBanner.Root>
      ))}
    </Portal.Root>
  )
}

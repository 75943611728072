import { useHasPractice } from 'api/hooks/companies'
import type { Company } from 'api/types/companies'
import type { User } from 'api/types/users'
import { getDefaultBankFeedFilterSearch } from 'domains/bank-feed/utils'
import { useHasFeatureFlag } from 'domains/feature-flag/queries'
import { useHasGetStarted } from 'domains/get-started/hooks'
import { FormattedMessage } from 'domains/i18n/components'
import { useMatchMedia } from 'kitchen/hooks/use-match-media'
import {
  useNavigate,
  generatePath,
  ActiveRouterLink,
  MatchesPatterns,
} from 'kitchen/router'
import { Item, Separator, SidebarNav } from 'salad/components'
import * as Icons from 'salad/icons'
import { Box } from 'salad/primitives'
import { media } from 'salad/tokens'
import { Path } from '@app/constants'
import { CompanySwitcher } from '../company-switcher'
import { ExpensesNavItem } from './expenses-nav-item'

interface AppSidebarCompanyNavItemsProps {
  user: User
  company: Company
}

export function AppSidebarCompanyNavItems({
  user,
  company,
}: AppSidebarCompanyNavItemsProps) {
  const hasPractice = useHasPractice()
  const navigate = useNavigate()
  const isBp2 = useMatchMedia(media['bp2'])

  const [hasGetPaidPortal] = useHasFeatureFlag(company.id, ['GET_PAID_PORTAL'])

  const hasGetStartedFeatureQuery = useHasGetStarted({ user, company })

  const hasGetStartedFeatureEntry = hasGetStartedFeatureQuery.data

  return (
    <>
      <SidebarNav.Item as={ActiveRouterLink} to={Path.COMPANIES_HUB}>
        <Item.Start>
          <Icons.S24.Arrow variant="left" />
        </Item.Start>
        <Item.Content>
          <FormattedMessage
            id="app.app-sidebar-company-nav-items.hub"
            defaultMessage="{hasPractice, select, true {Practice hub } other {Hub}}"
            values={{ hasPractice }}
          />
        </Item.Content>
      </SidebarNav.Item>
      {isBp2 ? (
        <Box py={16}>
          <CompanySwitcher
            variant="sidebar"
            value={company}
            onValueChange={(company) =>
              navigate(generatePath(Path.COMPANIES_COMPANY_ID, { companyId: company.id }))
            }
          />
        </Box>
      ) : (
        <Separator variant="solid" size={1} my={12} px={10} />
      )}
      {hasGetStartedFeatureEntry && (
        <SidebarNav.Item
          as={ActiveRouterLink}
          to={generatePath(Path.COMPANY_ONBOARDING_CHECKLIST, {
            companyId: company.id,
          })}
          match={[{ path: Path.COMPANY_ONBOARDING_CHECKLIST, end: true }]}
        >
          <Item.Start>
            <Icons.S24.Bolt />
          </Item.Start>
          <Item.Content>
            <FormattedMessage
              id="app.app-sidebar-company-nav-items.get-started"
              defaultMessage="Get started"
            />
          </Item.Content>
        </SidebarNav.Item>
      )}
      <SidebarNav.Item
        as={ActiveRouterLink}
        to={generatePath(Path.PAY_INVOICES_PAY, { companyId: company.id })}
        match={[{ path: Path.PAY, end: false }]}
      >
        <Item.Start>
          <Icons.S24.CircleArrow variant="right" />
        </Item.Start>
        <Item.Content>
          <FormattedMessage
            id="app.app-sidebar-company-nav-items.pay"
            defaultMessage="Bill pay"
          />
        </Item.Content>
      </SidebarNav.Item>
      {hasGetPaidPortal ? (
        <SidebarNav.Item
          as={ActiveRouterLink}
          to={generatePath(Path.COMPANY_GET_PAID, { companyId: company.id })}
          match={[{ path: Path.COMPANY_GET_PAID, end: false }]}
        >
          <Item.Start>
            <Icons.S24.CircleArrow variant="left" />
          </Item.Start>
          <Item.Content>
            <FormattedMessage
              id="app.app-sidebar-company-nav-items.get-paid"
              defaultMessage="Get paid"
            />
          </Item.Content>
        </SidebarNav.Item>
      ) : null}
      <ExpensesNavItem companyId={company.id} />

      <MatchesPatterns
        patterns={[
          { path: Path.COMPANY_BOOKKEEPING, end: false },
          { path: Path.COMPANY_BANK_FEED, end: false },
        ]}
      >
        {(matches) => (
          <>
            <SidebarNav.Item
              as={ActiveRouterLink}
              to={generatePath(Path.COMPANY_BOOKKEEPING_DOCUMENTS_INBOX, {
                companyId: company.id,
              })}
              indicator="text-color"
              aria-current={matches}
            >
              <Item.Start>
                <Icons.S24.Document />
              </Item.Start>
              <Item.Content>
                <FormattedMessage
                  id="app.app-sidebar-company-nav-items.bookkeeping"
                  defaultMessage="Bookkeeping"
                />
              </Item.Content>
              <Item.End>
                <Icons.S16.Dropdown variant={matches ? 'pressed' : 'default'} />
              </Item.End>
            </SidebarNav.Item>
            {matches && (
              <>
                <SidebarNav.Item
                  as={ActiveRouterLink}
                  to={generatePath(Path.COMPANY_BOOKKEEPING_DOCUMENTS_INBOX, {
                    companyId: company.id,
                  })}
                  match={[{ path: Path.COMPANY_BOOKKEEPING_DOCUMENTS, end: false }]}
                >
                  <Item.Start css={{ size: 24 }} />
                  <Item.Content>
                    <FormattedMessage
                      id="app.app-sidebar-company-nav-items.purchases"
                      defaultMessage="Purchases"
                    />
                  </Item.Content>
                </SidebarNav.Item>
                <SidebarNav.Item
                  as={ActiveRouterLink}
                  to={generatePath(Path.COMPANY_BOOKKEEPING_SALES_DOCUMENTS_INBOX, {
                    companyId: company.id,
                  })}
                  match={[{ path: Path.COMPANY_BOOKKEEPING_SALES_DOCUMENTS, end: false }]}
                >
                  <Item.Start css={{ size: 24 }} />
                  <Item.Content>
                    <FormattedMessage
                      id="app.app-sidebar-company-nav-items.sales"
                      defaultMessage="Sales"
                    />
                  </Item.Content>
                </SidebarNav.Item>
                <SidebarNav.Item
                  as={ActiveRouterLink}
                  to={{
                    pathname: generatePath(Path.COMPANY_BANK_FEED, {
                      companyId: company.id,
                    }),
                    search: getDefaultBankFeedFilterSearch().toString(),
                  }}
                  match={[{ path: Path.COMPANY_BANK_FEED, end: false }]}
                >
                  <Item.Start css={{ size: 24 }} />
                  <Item.Content>
                    <FormattedMessage
                      id="app.app-sidebar-company-nav-items.unreconciled-transactions"
                      defaultMessage="Unreconciled transactions"
                    />
                  </Item.Content>
                </SidebarNav.Item>
              </>
            )}
          </>
        )}
      </MatchesPatterns>
      <SidebarNav.Item
        as={ActiveRouterLink}
        to={generatePath(Path.COMPANY_CONTACTS, {
          companyId: company.id,
        })}
        match={[{ path: Path.COMPANY_CONTACTS, end: false }]}
      >
        <Item.Start>
          <Icons.S24.Contacts />
        </Item.Start>
        <Item.Content>
          <FormattedMessage
            id="app.app-sidebar-company-nav-items.contacts"
            defaultMessage="Contacts"
          />
        </Item.Content>
      </SidebarNav.Item>
      <SidebarNav.Item
        as={ActiveRouterLink}
        to={generatePath(Path.COMPANY_SETTINGS, {
          companyId: company.id,
        })}
        match={[
          { path: Path.COMPANY_SETTINGS, end: false },
          { path: Path.COMPANY_SUBSCRIPTION_MANAGE, end: false },
        ]}
      >
        <Item.Start>
          <Icons.S24.Settings />
        </Item.Start>
        <Item.Content>
          <FormattedMessage
            id="app.app-sidebar-company-nav-items.settings"
            defaultMessage="Settings"
          />
        </Item.Content>
      </SidebarNav.Item>
    </>
  )
}

import { useAuthChannel } from 'domains/auth/hooks'
import { useRevokeAuthSession } from 'domains/auth/queries'
import { useNavigate } from 'kitchen/router'
import { setLogoutLocation } from 'kitchen/utils/auth'
import { useCallback } from 'react'
import { Path } from '../constants'

interface LogoutParams {
  broadcastToAllTabs?: boolean
}

export function useLogout() {
  const navigate = useNavigate()
  const authChannel = useAuthChannel()
  const { mutate: revokeAuthSession } = useRevokeAuthSession()

  return useCallback(
    async ({ broadcastToAllTabs = true }: LogoutParams = {}) => {
      navigate(Path.AUTH, { replace: true })
      revokeAuthSession()
      setLogoutLocation(null)

      if (broadcastToAllTabs) {
        authChannel.post({ type: 'logout' })
      }

      // for cleanup of intercom, sentry, etc. check useCleanupUserSession()
    },
    [authChannel, revokeAuthSession, navigate]
  )
}

import type { CountryCode } from 'api/types/address'
import { getCountryCodeFormatter } from 'kitchen/utils/address'
import { cdn } from 'kitchen/utils/helpers'
import type { InputButton } from 'salad/components'
import { Combobox, Item } from 'salad/components'
import { Image, HStack, Text } from 'salad/primitives'
import type { PhoneCountryCallingCode } from '../../types'
import { formatCountryCallingCode } from '../../utils'

const SEARCH_KEYS = ['label', 'value.countryCode', 'value.callingCode']

function compare(a: PhoneCountryCallingCode, b: PhoneCountryCallingCode) {
  return a.callingCode === b.callingCode && a.countryCode === b.countryCode
}

function groupBy({ value }: { value: PhoneCountryCallingCode }) {
  return value.countryCode[0]
}

function getCountryFlagImageFromCDN(code: CountryCode) {
  return cdn(`/flags/${code.toLowerCase()}.svg`)
}

interface PhoneCountryCallingCodeSelectProps
  extends Omit<InputButton.InputButtonProps, 'defaultValue' | 'value' | 'onChange'> {
  options: PhoneCountryCallingCode[]
  value: PhoneCountryCallingCode | null
  onChange: (value: PhoneCountryCallingCode | null) => void
}

export function PhoneCountryCallingCodeSelect({
  options,
  value,
  onChange,
  disabled,
  ...rest
}: PhoneCountryCallingCodeSelectProps) {
  const formatCountryName = getCountryCodeFormatter()
  const suggested = options.filter(({ countryCode }) => countryCode === 'GB')
  const formatted = options.map((option) => ({
    key: `${option.countryCode}-${option.callingCode}`,
    value: option,
    label: formatCountryCallingCode(option.callingCode),
    count: null,
  }))

  return (
    <Combobox<PhoneCountryCallingCode, (typeof formatted)[number]>
      suggested={suggested}
      options={formatted}
      value={value}
      onValueChange={onChange}
      searchKeys={SEARCH_KEYS}
      compare={compare}
      groupBy={groupBy}
      size={450}
      {...rest}
    >
      {(option) => (
        <Item.Root>
          <Item.Start>
            <Image
              src={getCountryFlagImageFromCDN(option.value.countryCode)}
              width={24}
              height={24}
              alt=""
            />
          </Item.Start>
          <Item.Content>
            <HStack gap={8} pl={4}>
              <Text variant="paragraph-14" color="grey-40">
                {option.label}
              </Text>
              <Text variant="title-14">
                {formatCountryName(option.value.countryCode)}
              </Text>
            </HStack>
          </Item.Content>
        </Item.Root>
      )}
    </Combobox>
  )
}

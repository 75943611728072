import { Container, IconButton, ProgressBar } from '../../components'
import * as Icons from '../../icons'
import { Grid, VStack, ZStack, Box, Text } from '../../primitives'
import { theme, styled } from '../../stitches'
import type { Color } from '../../tokens'

const HalvesGrid = styled(Grid, {
  gridAutoFlow: 'column',
  '@bp0': { gridTemplateColumns: '1fr' },
  '@bp3': { gridTemplateColumns: '1fr 1fr' },
})

const HalvesGridSide = styled(VStack, {
  gap: theme.space[48],
  alignContent: 'start',
  paddingBlock: theme.space[48],
  '@bp0': { '&:nth-child(2)': { display: 'none' } },
  '@bp3': { '&:nth-child(2)': { display: 'grid' }, padding: theme.space[80] },
})

interface HalvesLayoutProps {
  backgrounds: [Color | null, Color | null]
  children?: React.ReactNode
}

function HalvesLayout({ backgrounds, children }: HalvesLayoutProps) {
  return (
    <ZStack style={{ minHeight: '100%' }}>
      <HalvesGrid>
        {backgrounds.map((color, index) => (
          <HalvesGridSide
            key={index}
            style={{ backgroundColor: theme.colors[color ?? 'transparent'].toString() }}
          />
        ))}
      </HalvesGrid>
      <Container style={{ display: 'grid' }}>
        <HalvesGrid>{children}</HalvesGrid>
      </Container>
    </ZStack>
  )
}

interface HalvesLayoutHeaderProps<Step> {
  value?: Step
  steps?: Step[]
  onBack?: () => void
  children?: React.ReactNode
}

function HalvesLayoutHeader<Step>({
  value,
  steps = [],
  onBack,
  children,
}: HalvesLayoutHeaderProps<Step>) {
  return (
    <VStack gap={16}>
      <VStack gap={2}>
        {onBack ? (
          <IconButton size={32} onClick={() => onBack()}>
            <Icons.S24.Arrow variant="left" />
          </IconButton>
        ) : (
          <Box style={{ height: 32 }} />
        )}
        <VStack gap={4}>{children}</VStack>
      </VStack>
      {value !== undefined && steps.length > 0 && (
        <ProgressBar
          variant="steps"
          value={steps.indexOf(value)}
          total={steps.length - 1}
          style={{ width: 240, height: 8 }}
        />
      )}
    </VStack>
  )
}

interface HalvesLayoutTitleProps {
  children?: React.ReactNode
}

function HalvesLayoutTitle({ children }: HalvesLayoutTitleProps) {
  return (
    <Text as="h1" variant="headline-h1" color="black">
      {children}
    </Text>
  )
}

interface HalvesLayoutSubtitleProps {
  children?: React.ReactNode
}

function HalvesLayoutSubtitle({ children }: HalvesLayoutSubtitleProps) {
  return (
    <Text as="p" variant="paragraph-20" color="black-alpha-60">
      {children}
    </Text>
  )
}

export {
  HalvesLayout as Root,
  HalvesGridSide as Side,
  HalvesLayoutHeader as Header,
  HalvesLayoutTitle as Title,
  HalvesLayoutSubtitle as Subtitle,
}

import type {
  Company,
  CompanySettings,
  CompanySettingsResponse,
} from 'api/types/companies'

export const isOnboardedCompany = (company: Company) =>
  !['NONE', 'FILLING_INFO'].includes(company.onboardingStatus)

export const getRequiredCompanySettings = (
  response: CompanySettingsResponse
): CompanySettings => ({
  hideAgedBills: 'NO_HIDE',
  onboardingStateBanner: 'VISIBLE',
  makePayrollEasierBanner: 'VISIBLE',
  freeSubscriptionBanner: 'VISIBLE',
  subscriptionMigrationBanner: 'VISIBLE',
  billsSyncedBanner: 'VISIBLE',
  createApronBeneficiaryBanner: 'VISIBLE',
  pickBookkeepingSyncModeBanner: 'VISIBLE',
  paymentsFreeTrialBanner: 'VISIBLE',
  defaultPayrunIsGrouped: undefined,
  missingScopes: undefined,
  bankFeedDisplaySettings: undefined,
  setupBookkeepingAssetsBanner: undefined,
  showNonReimbursableReceipts: undefined,
  ...response,
  initialOnboarding: {
    isAppDownloadAcknowledged: false,
    isDocumentForwardAcknowledged: false,
    isPaymentBatchReviewed: false,
    numberOfBillsPerMonth: null,
    paymentDuration: null,
    steps: [],
    ...response.initialOnboarding,
  },
})

/**
 * in current data model we assume that among companies
 * there can only be 1 practice. If it exists, we're in the Practice hub world
 */
export const getPracticeCompany = (companies: Company[]) =>
  companies.find(isPracticeCompany)

export const isPracticeCompany = (company: Company) => company.type === 'PRACTICE'
export const notPracticeCompany = (company: Company) => company.type !== 'PRACTICE'

export const getCompanyName = (company: Company) =>
  company.name ??
  company.legalName ??
  (company.type === 'PRACTICE' ? 'Practice' : 'Company')

import type { ApiRequest } from 'kitchen/types'
import { matchSorter } from 'match-sorter'
import type {
  ExpenseTransaction,
  ExpenseTransactionsPayload,
  ExpenseTransactionsResponse,
} from './types'

// temporary endpoint for beta Expenses release.
// to be removed for GA.
export const getExpenseTransactions: ApiRequest<
  ExpenseTransactionsPayload,
  ExpenseTransaction[]
> = (fetch, payload, signal) =>
  fetch
    .post('/expenses/transactions/list', { json: payload, signal })
    .json<ExpenseTransactionsResponse>()
    .then((data) => {
      if (payload.filter.search === '') {
        return data.expenses
      }
      return matchSorter(data.expenses, payload.filter.search, {
        keys: ['merchantName', 'cardName', 'cardHolder'],
      })
    })

import type { CompanyId } from 'domains/company/types'
import { useFeatureWaitlist, useHasFeatureFlag } from 'domains/feature-flag/queries'
import { FormattedMessage } from 'domains/i18n/components'
import { generatePath, ActiveRouterLink } from 'kitchen/router'
import { SidebarNav, Chip, Item } from 'salad/components'
import * as Icons from 'salad/icons'
import { Path } from '@app/constants'
import { useShouldPromoteExpensesFeature } from '../../features/expenses/use-should-promote-expenses-feature'

interface ExpensesNavItemProps {
  companyId: CompanyId
}

export const ExpensesNavItem = ({ companyId }: ExpensesNavItemProps) => {
  const [hasCardExpenses, hasCardExpensesWaitlist] = useHasFeatureFlag(companyId, [
    'CARD_EXPENSES',
    'CARD_EXPENSES_WAITLIST',
  ])
  const waitlistQuery = useFeatureWaitlist(companyId, {
    enabled: hasCardExpensesWaitlist,
  })

  const hasExpensesFeatureEntry = hasCardExpenses || hasCardExpensesWaitlist
  const shouldPromoteExpensesFeature = useShouldPromoteExpensesFeature(companyId, {
    enabled: hasCardExpenses,
  })

  // either we're in the Waitlist and haven't joined, or the feature is on but there are no transactions.
  const shouldRenderNewChip =
    (waitlistQuery.status === 'success' && !waitlistQuery.data.includes('EXPENSES')) ||
    shouldPromoteExpensesFeature

  if (!hasExpensesFeatureEntry) {
    return null
  }

  return (
    <SidebarNav.Item
      as={ActiveRouterLink}
      to={generatePath(Path.COMPANY_EXPENSES, { companyId })}
      match={[{ path: Path.COMPANY_EXPENSES, end: false }]}
    >
      <Item.Start>
        <Icons.S24.Pound />
      </Item.Start>
      <Item.Content>
        <FormattedMessage
          id="app.app-sidebar-company-nav-items.expenses"
          defaultMessage="Expenses"
        />
      </Item.Content>
      {shouldRenderNewChip && (
        <Item.End>
          <Chip variant="neutral">
            <FormattedMessage
              id="app.app-sidebar-company-nav-items.expenses.new"
              defaultMessage="New"
            />
          </Chip>
        </Item.End>
      )}
    </SidebarNav.Item>
  )
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  TrackingCategory,
  TrackingCategoryDictionaryOption,
  TrackingCategoryOptionId,
} from 'domains/tracking-category/types'
import { CacheKey, Time } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import type { FetchError, UseMutationOptions, UseQueryOptions } from 'kitchen/types'
import { getRequiredCompanySettings, isPracticeCompany } from 'kitchen/utils/companies'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import { checkHasCompanyAccess } from 'kitchen/utils/permissions'
import { getCompanyUserRole } from 'kitchen/utils/roles'
import { isAccountingApp } from 'kitchen/utils/vendor'
import { useCallback, useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import {
  getCompanies,
  getCompanySettings,
  getCompanyStatus,
  createCompany,
  updateCompanySettings,
  syncCompany,
  disconnectCompany,
  getApprovalWorkflows,
  updateCompany,
  updateApprovalWorkflow,
  removeApprovalWorkflow,
  createApprovalWorkflow,
  getUserDependencies,
  updateCompanyUserDirectEmail,
  getCompanyAdvancedSettings,
  getTrackingCategoriesList,
  refreshCompanyAccountingData,
  getCompaniesStats,
  generateCompanyUserInvoiceEmail,
  noSyncSetupCompany,
} from '../requests/companies'
import type {
  Company,
  CompanyId,
  CompanySettings,
  UpdateCompanySettingsPayload,
  CreateCompanyResponse,
  CompanyIdPayload,
  CompanyStatusResponse,
  ApprovalWorkflow,
  UpdateCompanyPayload,
  UpdateApprovalWorkflowPayload,
  CompanySyncStatus,
  RemoveApprovalWorkflowPayload,
  CreateApprovalWorkflowPayload,
  UserDependenciesPayload,
  UserDependenciesResponse,
  CompanyUserDependencies,
  UpdateCompanyUserDirectEmailPayload,
  AdvancedCompanySettings,
  CompaniesListPayload,
  CompaniesStats,
  GenerateCompanyUserInvoiceEmailPayload,
  GeneratedCompanyUserInvoiceEmail,
} from '../types/companies'
import { useCurrentUser } from './users'

export interface CompaniesOptions<Result = Company[]>
  extends UseQueryOptions<Company[], Result> {}

export const useCompanies = <Result = Company[]>(
  { status = ['DRAFT', 'ACTIVE'] }: CompaniesListPayload,
  options?: CompaniesOptions<Result>
) => {
  const fetch = useFetch()
  return useQuery<Company[], FetchError, Result>(
    [CacheKey.COMPANIES],
    ({ signal }) => getCompanies(fetch, { status }, signal),
    {
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export const useCompaniesStats = (options?: UseQueryOptions<CompaniesStats>) => {
  const fetch = useFetch()

  return useQuery<CompaniesStats, FetchError>(
    [CacheKey.COMPANIES, CacheKey.STATS],
    ({ signal }) => getCompaniesStats(fetch, signal),
    options
  )
}

export interface CompanyOptions extends CompaniesOptions<Company | undefined> {}

export function useCompany(id: CompanyId | undefined, options?: CompanyOptions) {
  const selectCompany = useCallback(
    (companies: Company[]) => companies.find((company) => company.id === id),
    [id]
  )

  return useCompanies(
    { status: ['DRAFT', 'ACTIVE'] },
    { select: selectCompany, ...options }
  )
}

export interface VisibleCompaniesOptions extends Omit<CompaniesOptions, 'select'> {}

export function useVisibleCompanies(options?: VisibleCompaniesOptions) {
  const user = useCurrentUser().data

  const selectVisibleCompanies = useCallback(
    (companies: Company[]) =>
      companies.filter((company) => {
        if (company.status === 'DRAFT' || user === undefined) {
          return false
        }

        return checkHasCompanyAccess(user.id, company)
      }),
    [user]
  )

  return useCompanies(
    { status: ['DRAFT', 'ACTIVE'] },
    { ...options, select: selectVisibleCompanies }
  )
}

export interface PracticeOptions
  extends Omit<CompaniesOptions<Company | undefined>, 'select'> {}

export function usePractice(options?: PracticeOptions) {
  const selectPractice = useCallback(
    (companies: Company[]) => companies.find(isPracticeCompany),
    []
  )

  return useCompanies(
    { status: ['DRAFT', 'ACTIVE'] },
    { select: selectPractice, ...options }
  )
}

export function useHasPractice() {
  const practice = usePractice()
  return practice.data !== undefined
}

export const useCompanySettings = (
  payload: CompanyIdPayload,
  options?: UseQueryOptions<CompanySettings>
) => {
  const fetch = useFetch()

  return useQuery<CompanySettings, FetchError>(
    [CacheKey.COMPANY_SETTINGS, payload.companyId],
    ({ signal }) => getCompanySettings(fetch, payload, signal),
    { staleTime: 5 * Time.MINUTE, ...options }
  )
}

export const useAdvancedCompanySettings = (
  payload: CompanyIdPayload,
  options?: UseQueryOptions<AdvancedCompanySettings>
) => {
  const fetch = useFetch()

  return useQuery<AdvancedCompanySettings, FetchError>(
    [CacheKey.ADVANCED_COMPANY_SETTINGS, payload.companyId],
    ({ signal }) => getCompanyAdvancedSettings(fetch, payload, signal),
    { staleTime: 5 * Time.MINUTE, ...options }
  )
}

export const useTrackingCategoriesList = (
  payload: CompanyIdPayload,
  options?: UseQueryOptions<TrackingCategory[]>
) => {
  const fetch = useFetch()

  return useQuery<TrackingCategory[], FetchError>(
    [CacheKey.COMPANY_TRACKING_CATEGORIES, payload.companyId],
    ({ signal }) => getTrackingCategoriesList(fetch, payload, signal),
    { staleTime: 5 * Time.MINUTE, ...options }
  )
}

/**
 * Temporary  solution while tracking category options list isn't implemented
 * TODO: move to a dedicated endpoint when it's ready
 */
export const useTrackingCategoryOptionsList = (
  payload: {
    companyId: CompanyId
    trackingCategoryName: TrackingCategory['name']
  },
  options?: UseQueryOptions<TrackingCategoryDictionaryOption[]>
) => {
  const fetch = useFetch()

  return useQuery<TrackingCategoryDictionaryOption[], FetchError>(
    [],
    ({ signal }) =>
      getTrackingCategoriesList(fetch, payload, signal).then((trackingCategories) => {
        const trackingCategory = trackingCategories.find(
          (trackingCategory) =>
            trackingCategory.name.toLowerCase() ===
            payload.trackingCategoryName.toLowerCase()
        )

        if (trackingCategory === undefined) {
          return []
        }

        if (trackingCategory.optionList !== undefined) {
          return trackingCategory.optionList
        }

        if (trackingCategory.options !== undefined) {
          return trackingCategory.options.map((option, index) => {
            return {
              id: index.toString() as TrackingCategoryOptionId,
              name: option,
            }
          })
        }

        return []
      }),
    options
  )
}

export const useCompanyStatus = (
  payload: CompanyIdPayload,
  options?: UseQueryOptions<CompanyStatusResponse>
) => {
  const fetch = useFetch()

  return useQuery<CompanyStatusResponse, FetchError>(
    [CacheKey.COMPANY_STATUS, payload.companyId],
    ({ signal }) => getCompanyStatus(fetch, payload, signal),
    options
  )
}

export const useUpdateCompanySettings = (
  options?: UseMutationOptions<UpdateCompanySettingsPayload, void>
) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()
  return useMutation((payload) => updateCompanySettings(fetch, payload), {
    ...options,
    onMutate(payload) {
      queryClient.setQueriesData<CompanySettings>(
        [CacheKey.COMPANY_SETTINGS, payload.companyId],
        (prev) => getRequiredCompanySettings({ ...prev, ...payload })
      )
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([
        CacheKey.COMPANY_SETTINGS,
        variables.companyId,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useCreateCompany = (
  options?: UseMutationOptions<void, CreateCompanyResponse>
) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()
  return useMutation(() => createCompany(fetch), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useUpdateCompany = (
  options?: UseMutationOptions<UpdateCompanyPayload, void>
) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()
  return useMutation((payload) => updateCompany(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      await queryClient.invalidateQueries([
        CacheKey.ADVANCED_COMPANY_SETTINGS,
        variables.companyId,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export function useUpdateIntercomCompany(company: Company) {
  const { update: updateIntercom } = useIntercom()

  useEffect(() => {
    updateIntercom({
      company: {
        companyId: company.id,
        name: company.name ?? undefined,
        customAttributes: { source: company.source, segment: company.segment },
      },
    })
  }, [company.id, company.name, company.source, company.segment, updateIntercom])
}

export function useSyncWithAccountingApp(
  company: Company,
  options?: UseQueryOptions<CompanySyncStatus>
) {
  const fetch = useFetch()
  return useQuery<CompanySyncStatus, FetchError>(
    [CacheKey.COMPANY_SYNC, company.id, company.source],
    ({ signal }) =>
      isAccountingApp(company.source) && !company.isConnectionLost
        ? syncCompany(fetch, { companyId: company.id }, signal).then(() => 'COMPLETED')
        : Promise.resolve('NONE'),
    {
      enabled: company !== undefined,
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export function useDisconnectCompany(
  options?: UseMutationOptions<CompanyIdPayload, void>
) {
  const fetch = useFetch()
  const queryClient = useQueryClient()
  return useMutation((payload) => disconnectCompany(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export interface ApprovalWorkflowsOptions<Result = ApprovalWorkflow[]>
  extends UseQueryOptions<ApprovalWorkflow[], Result> {}

export const useApprovalWorkflows = <Result = ApprovalWorkflow[]>(
  payload: CompanyIdPayload,
  options?: ApprovalWorkflowsOptions<Result>
) => {
  const fetch = useFetch()

  return useQuery<ApprovalWorkflow[], FetchError, Result>(
    [CacheKey.COMPANY_WORKFLOWS, payload.companyId],
    ({ signal }) => getApprovalWorkflows(fetch, payload, signal),
    { ...immutableRequestOptions, ...options }
  )
}

export const useCreateApprovalWorkflow = (
  options?: UseMutationOptions<CreateApprovalWorkflowPayload, void>
) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()

  return useMutation((payload) => createApprovalWorkflow(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([
        CacheKey.COMPANY_WORKFLOWS,
        variables.companyId,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useUpdateApprovalWorkflow = (
  options?: UseMutationOptions<UpdateApprovalWorkflowPayload, void>
) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()

  return useMutation((payload) => updateApprovalWorkflow(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([
        CacheKey.COMPANY_WORKFLOWS,
        variables.companyId,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useRemoveApprovalWorkflow = (
  options?: UseMutationOptions<RemoveApprovalWorkflowPayload, void>
) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()

  return useMutation(async (payload) => removeApprovalWorkflow(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([
        CacheKey.COMPANY_WORKFLOWS,
        variables.companyId,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export interface UserDependenciesOptions<Select = UserDependenciesResponse>
  extends UseQueryOptions<UserDependenciesResponse, Select> {}

export const useUserDependencies = <Select = UserDependenciesResponse>(
  payload: UserDependenciesPayload,
  options?: UserDependenciesOptions<Select>
) => {
  const fetch = useFetch()
  return useQuery<UserDependenciesResponse, FetchError, Select>(
    [CacheKey.USER_DEPENDENCIES, payload.companyId, payload.userId],
    ({ signal }) => getUserDependencies(fetch, payload, signal),
    options
  )
}

export const useCompaniesUserDependencies = (payload: UserDependenciesPayload) => {
  const companies = useCompanies({ status: ['DRAFT', 'ACTIVE'] })

  return useUserDependencies(payload, {
    enabled: companies.isSuccess,
    select: (data) => {
      if (companies.data === undefined) {
        return []
      }

      return companies.data.reduce<CompanyUserDependencies[]>((acc, company) => {
        const userRole = getCompanyUserRole(payload.userId, company) ?? 'CUSTOM'

        if (
          company.id === payload.companyId &&
          (data.workflows.length > 0 || data.pendingPayments.length > 0)
        ) {
          acc.push({
            company,
            userRole,
            workflows: data.workflows,
            pendingPayments: data.pendingPayments,
            requiredPermissions: data.requiredPermissions,
          })
        }

        const clientDependency = data.clientDependencies[company.id]

        if (clientDependency) {
          acc.push({
            company,
            userRole,
            workflows: clientDependency.workflows,
            pendingPayments: clientDependency.pendingPayments,
            requiredPermissions: data.requiredPermissions,
          })
        }

        return acc
      }, [])
    },
  })
}

export const useUpdateCompanyUserDirectEmail = (
  options?: UseMutationOptions<UpdateCompanyUserDirectEmailPayload, void>
) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()

  return useMutation((payload) => updateCompanyUserDirectEmail(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useGenerateCompanyUserInvoiceEmail = (
  options?: UseMutationOptions<
    GenerateCompanyUserInvoiceEmailPayload,
    GeneratedCompanyUserInvoiceEmail
  >
) => {
  const fetch = useFetch()

  return useMutation((payload) => generateCompanyUserInvoiceEmail(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useRefreshCompanyAccountingData = (
  options?: UseMutationOptions<CompanyIdPayload, void>
) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()

  return useMutation((payload) => refreshCompanyAccountingData(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      await queryClient.invalidateQueries([CacheKey.COMPANY_TRACKING_CATEGORIES])
      await queryClient.invalidateQueries([CacheKey.TAX_TYPES])

      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useNoSyncSetupCompany = (
  options?: UseMutationOptions<CompanyIdPayload, void>
) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()

  return useMutation((payload) => noSyncSetupCompany(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      await queryClient.invalidateQueries([
        CacheKey.COMPANY_SETTINGS,
        variables.companyId,
      ])
      await queryClient.invalidateQueries([
        CacheKey.FINANCIAL_ACCOUNTS,
        variables.companyId,
      ])
      await queryClient.invalidateQueries([CacheKey.TAX_TYPES, variables.companyId])
      await queryClient.invalidateQueries([CacheKey.CONTACTS, variables.companyId])

      return options?.onSuccess?.(data, variables, context)
    },
  })
}

import type { CountryCode, Address, AddressTemplate } from 'api/types/address'
import type * as CA from 'api/types/companies-house'
import type * as GA from 'api/types/get-address'
import type { BillingAddress } from 'domains/billing/types'
import { getIntl } from 'domains/i18n/utils'

export const isCountryCode = (input: unknown): input is CountryCode =>
  typeof input === 'string' && input.length === 2

export function toCountryCode(input: string): CountryCode {
  if (!isCountryCode(input)) {
    throw new Error('Not a valid country code')
  }

  return input
}

export function getCountryCodeFormatter() {
  const { locale } = getIntl()
  if (typeof Intl.DisplayNames !== 'function') {
    return function formatCountryCode(input: CountryCode | null | undefined) {
      if (typeof input === 'string') {
        return input
      }
      return undefined
    }
  }

  const regionNames = new Intl.DisplayNames([locale], {
    type: 'region',
  })

  return function formatCountryCode(input: CountryCode | null | undefined) {
    if (typeof input === 'string') {
      try {
        return regionNames.of(input)
      } catch (_) {}
    }
    return undefined
  }
}

export function resolveCountryCode(input: unknown) {
  switch (input) {
    case 'England':
    case 'Wales':
    case 'Scotland':
    case 'Northern Ireland':
    case 'Great Britain':
    case 'United Kingdom':
      return toCountryCode('GB')
    case 'Not specified':
    default:
      return undefined
  }
}

export function toAddress(
  input: GA.Address | CA.Address | BillingAddress | AddressTemplate
): Address {
  if ('thoroughfare' in input) {
    return {
      country: resolveCountryCode(input.country),
      streetLine1: [input.line1, input.line2].filter(Boolean).join(', '),
      streetLine2: [input.line3, input.line4].filter(Boolean).join(', '),
      city: input.townOrCity,
      region: input.county,
      zipCode: input.postcode,
    }
  }

  if ('locality' in input) {
    return {
      country: toCountryCode(input.country),
      streetLine1: input.addressLine1,
      streetLine2: input.addressLine2,
      city: input.locality,
      region: input.region,
      zipCode: input.postalCode,
    }
  }

  return {
    country:
      input.country ||
      ('countryName' in input && resolveCountryCode(input.countryName)) ||
      undefined,
    streetLine1: input.streetLine1 || '',
    streetLine2: input.streetLine2 || undefined,
    city: input.city || '',
    zipCode:
      ('zipCode' in input && input.zipCode) ||
      ('postcode' in input && input.postcode) ||
      '',
    region: ('region' in input && input.region) || undefined,
  }
}

export function getAddressFieldLabel(
  key: keyof Address | 'postcode',
  country?: CountryCode
) {
  switch (key) {
    case 'streetLine1':
      return 'Address line 1'
    case 'streetLine2':
      return 'Address line 2'
    case 'city':
      return 'City'
    case 'country':
      return 'Country'
    case 'zipCode':
    case 'postcode':
      return 'Postcode'
    case 'region':
      return getRegionLabel(country)
  }
}

export function getRegionLabel(country: CountryCode | undefined) {
  switch (country) {
    case 'MX':
    case 'US':
      return 'State'
    default:
      return 'Province'
  }
}

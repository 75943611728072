import Tracker, { SanitizeLevel } from '@openreplay/tracker'
import { useHasFeatureFlag } from 'domains/feature-flag/queries'
import { OPENREPLAY_EXCLUDE_CLASS, OPENREPLAY_MASK_CLASS } from 'kitchen/constants'
import { createContext, useState, useEffect } from 'react'

export const OpenReplayContext = createContext<Tracker | null>(null)

export const OpenReplayProvider = ({ children }: { children?: React.ReactNode }) => {
  const [tracker, setTracker] = useState<Tracker | null>(null)
  const [isUserSessionRecordingEnabled] = useHasFeatureFlag(
    ['USER_SESSION_RECORDING_ENABLED'],
    { suspense: false }
  )

  useEffect(() => {
    const shouldInitializeTracker =
      isUserSessionRecordingEnabled &&
      (process.env.BUILD_MODE === 'staging' || process.env.BUILD_MODE === 'production') &&
      process.env.PLAYWRIGHT === undefined && // disable for test runs
      tracker === null

    if (shouldInitializeTracker) {
      const newTracker = new Tracker({
        projectKey: process.env.OPENREPLAY_PROJECT_KEY,
        ingestPoint: process.env.OPENREPLAY_INGEST_POINT,
        network: {
          sessionTokenHeader: false,
          failuresOnly: false,
          ignoreHeaders: ['authorization'],
          captureInIframes: true,
          capturePayload: false,
          useProxy: false, // otherwise OpenReplay's fetch proxy conflicts with ya-fetch and requests are not tracked by OpenReplay as a result
        },
        domSanitizer: (node: Element) => {
          const elementClassNames = node.classList

          if (elementClassNames.contains(OPENREPLAY_MASK_CLASS)) {
            return SanitizeLevel.Obscured
          }

          if (elementClassNames.contains(OPENREPLAY_EXCLUDE_CLASS)) {
            return SanitizeLevel.Hidden
          }

          return SanitizeLevel.Plain
        },
      })
      newTracker.start()
      setTracker(newTracker)
    }
  }, [tracker, isUserSessionRecordingEnabled])

  return (
    <OpenReplayContext.Provider value={tracker}>{children}</OpenReplayContext.Provider>
  )
}

import type { Company } from 'api/types/companies'
import { JoinUsIllustration } from 'domains/practice/components'
import { ImpossibleError } from 'kitchen/utils/error'
import { Portal } from 'radix-ui'
import { Status, Spinner, Delayed, Surface, GlobalBlur } from 'salad/components'
import { HalvesLayout, AppLayout, StatusLayout } from 'salad/layouts'
import { theme } from 'salad/stitches'
import { AppHeader } from './app-header'
import { AppSidebar } from './app-sidebar'

export type LoadingStatusProps =
  | {
      layout: 'flow-page' | 'inline' | 'dialog' | 'overlay' | 'halves-onboarding'
      delay?: number
    }
  | {
      layout: 'company-page'
      company: Company
      title: React.ReactNode
      onBack?: () => void
      delay?: number
    }
  | {
      layout: 'hub-page'
      practice: Company | undefined
      delay?: number
    }

export function LoadingStatus({ delay, ...rest }: LoadingStatusProps) {
  const spinner = (
    <Spinner size={64} color={rest.layout === 'overlay' ? 'white' : 'grey-10'} />
  )

  const content = (
    <Status.Root>
      <Status.Icon>{spinner}</Status.Icon>
    </Status.Root>
  )

  switch (rest.layout) {
    case 'company-page':
    case 'hub-page':
      return (
        <Delayed delay={delay}>
          <AppLayout.Root>
            <AppLayout.Side>
              <AppSidebar {...rest} />
            </AppLayout.Side>
            <AppLayout.Header>
              <AppHeader {...rest} />
            </AppLayout.Header>
            <AppLayout.Content align="center">{spinner}</AppLayout.Content>
            <AppLayout.End />
          </AppLayout.Root>
        </Delayed>
      )
    case 'flow-page':
      return (
        <Delayed delay={delay}>
          <StatusLayout.Root>
            <StatusLayout.Content>{content}</StatusLayout.Content>
          </StatusLayout.Root>
        </Delayed>
      )
    case 'halves-onboarding':
      return (
        <Delayed delay={delay}>
          <HalvesLayout.Root backgrounds={[null, 'light-yellow-60']}>
            <HalvesLayout.Side style={{ placeContent: 'stretch' }}>
              {content}
            </HalvesLayout.Side>
            <HalvesLayout.Side style={{ placeContent: 'center' }}>
              <JoinUsIllustration />
            </HalvesLayout.Side>
          </HalvesLayout.Root>
        </Delayed>
      )
    case 'overlay':
      return (
        <Delayed delay={delay}>
          <GlobalBlur />
          <Portal.Root>
            <StatusLayout.Root
              css={{
                position: 'fixed',
                inset: 0,
                backgroundColor: theme.colors['black-alpha-10'],
              }}
            >
              <StatusLayout.Content>{content}</StatusLayout.Content>
            </StatusLayout.Root>
          </Portal.Root>
        </Delayed>
      )
    case 'inline':
      return <Delayed delay={delay}>{content}</Delayed>
    case 'dialog':
      return (
        <Delayed delay={delay}>
          <Surface variant="flat" p={32}>
            {content}
          </Surface>
        </Delayed>
      )
    default:
      throw new ImpossibleError('Unhandled layout', rest)
  }
}

import { FormattedMessage } from 'domains/i18n/components'
import { cdn } from 'kitchen/utils/helpers'
import { PhotoFrame } from 'salad/components'
import { Grid, Image, VStack, Text } from 'salad/primitives'

export function JoinUsIllustration() {
  return (
    <VStack as="figure" gap={40} style={{ maxWidth: 540 }}>
      <Grid style={{ gridAutoFlow: 'column', justifyContent: 'center' }}>
        <PhotoFrame
          frameWidth="small"
          style={{
            transform: 'rotate(-10.58deg)',
            filter: 'drop-shadow(0px 9.74638px 19.4928px rgba(93, 56, 0, 0.1))',
          }}
        >
          <Image
            src={cdn('/photos/remi-williams.jpg')}
            srcSet={cdn('/photos/remi-williams.jpg', '/photos/remi-williams@2x.jpg 2x')}
            width={220}
            height={330}
            alt="Remi Williams, co-founder of Smoke & Salt"
          />
        </PhotoFrame>
        <PhotoFrame
          mt={56}
          frameWidth="small"
          dogEarSide="bottom-right"
          style={{
            transform: 'rotate(10.79deg)',
            filter: 'drop-shadow(0px 9.74638px 19.4928px rgba(93, 56, 0, 0.1))',
          }}
        >
          <Image
            src={cdn('/photos/rich-sykes.jpg')}
            srcSet={cdn('/photos/rich-sykes.jpg', '/photos/rich-sykes@2x.jpg 2x')}
            width={220}
            height={330}
            alt="Rich Sykes, founder of CloudCFO"
          />
        </PhotoFrame>
      </Grid>
      <Text as="figcaption" variant="website-h3">
        <FormattedMessage
          id="practice.join-us.caption"
          defaultMessage="<b>Join 10,000+ business owners.</b> Clarity and control over bills, books and expenses, you’re  unstoppable."
          values={{ b: (chunks) => <Text weight={800}>{chunks}</Text> }}
        />
      </Text>
    </VStack>
  )
}

import { Slot } from 'radix-ui'
import { createContext } from 'react'
import { Container } from '../../components'
import { Grid } from '../../primitives'
import { styled, theme } from '../../stitches'

type AppLayoutSize = 'auto' | 'full' | 448
const AppLayoutContext = createContext<AppLayoutSize>('auto')

const AppLayoutBase = styled(Grid, {
  minHeight: '100%',
  isolation: 'isolate',
  gridTemplate: `
    'start  ' auto
    'banner ' auto
    'content' minmax(0, 1fr)
    'end    ' auto / 1fr
  `,
  '@bp2': {
    gridTemplate: `
      'side banner ' auto
      'side start  ' auto
      'side content' minmax(0, 1fr)
      'side end    ' auto / auto 1fr
    `,
  },
  '[data-app-layout-header]': {
    position: 'sticky',
    top: 'var(--app-header-offset, 0)',
    zIndex: 1,
    minHeight: 'auto',
  },
  variants: {
    size: {
      auto: {},
      full: {
        minHeight: 0,
        height: '100%',
        overflow: 'auto',
        '[data-app-layout-header]': { top: 0 },
      },
      448: {},
    },
  },
  defaultVariants: {
    size: 'auto',
  },
})

const AppLayoutContainer = styled('div', {
  variants: {
    size: {
      auto: {},
      full: {
        width: '100%',
        minWidth: 0,
      },
      448: {
        width: '100%',
        marginInline: 'auto',
        maxWidth: 480,
        paddingInline: theme.space[16],
      },
    },
    align: {
      center: { placeSelf: 'center' },
    },
  },
})

export interface AppLayoutStartProps
  extends React.ComponentProps<typeof AppLayoutStart> {}

const AppLayoutStart = styled('div', {
  gridArea: 'start',
  minHeight: 64,
})

export interface AppLayoutBannerProps
  extends React.ComponentProps<typeof AppLayoutBanner> {}

const AppLayoutBanner = styled('div', {
  gridArea: 'banner',
})

export interface AppLayoutSideProps extends React.ComponentProps<typeof AppLayoutSide> {}

const AppLayoutSide = styled('div', {
  display: 'none',
  '@bp2': {
    display: 'block',
    gridArea: 'side',
    position: 'sticky',
    top: 'var(--app-header-offset, 0px)',
    height: 'calc(100vh - var(--app-header-offset, 0px))',
  },
})

export interface AppLayoutProps extends React.ComponentProps<typeof AppLayoutBase> {
  size?: AppLayoutSize
}

const AppLayout = ({ size, ...rest }: AppLayoutProps) => (
  <AppLayoutContext.Provider value={size ?? 'auto'}>
    <AppLayoutBase size={size} {...rest} />
  </AppLayoutContext.Provider>
)

export interface AppLayoutHeaderProps
  extends React.ComponentProps<typeof AppLayoutStart> {}

const AppLayoutHeader = ({ children, ...rest }: AppLayoutHeaderProps) => (
  <AppLayoutStart as="header" data-app-layout-header {...rest}>
    {children}
  </AppLayoutStart>
)

export interface AppLayoutContentProps
  extends React.ComponentProps<typeof Container>,
    Pick<React.ComponentProps<typeof AppLayoutContainer>, 'align'> {
  asChild?: boolean
}

const AppLayoutContent = ({ asChild, align, css, ...rest }: AppLayoutContentProps) => (
  <AppLayoutContext.Consumer>
    {(size) =>
      size === 'auto' && align === undefined ? (
        <Container css={{ gridArea: 'content', ...css }} {...rest} />
      ) : (
        <AppLayoutContainer
          as={asChild ? Slot.Root : 'div'}
          align={align}
          size={size}
          css={{ gridArea: 'content', ...css }}
          {...rest}
        />
      )
    }
  </AppLayoutContext.Consumer>
)

const AppLayoutEnd = styled('div', {
  gridArea: 'end',
  paddingBlock: theme.space[32],
})

export interface AppLayoutFooterProps extends React.ComponentProps<typeof AppLayoutEnd> {}

const AppLayoutFooter = ({ children, ...rest }: AppLayoutFooterProps) => (
  <AppLayoutEnd as="footer" {...rest}>
    <Container>{children}</Container>
  </AppLayoutEnd>
)

export {
  AppLayout as Root,
  AppLayoutStart as Start,
  AppLayoutSide as Side,
  AppLayoutBanner as Banner,
  AppLayoutHeader as Header,
  AppLayoutContent as Content,
  AppLayoutFooter as Footer,
  AppLayoutEnd as End,
}

import { abbreviate, hash } from 'kitchen/utils/helpers'
import { Grid } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'

type AvatarColor =
  | 'celeste'
  | 'blue'
  | 'lavender'
  | 'purple'
  | 'pink'
  | 'silk'
  | 'wheat'
  | 'blond'
  | 'new'
  | 'done'
  | 'deleted'
  | 'disabled'
  | 'auto'

const FOREGROUNDS = {
  celeste: theme.colors['avatar-celeste-text'],
  blue: theme.colors['avatar-blue-text'],
  lavender: theme.colors['avatar-lavender-text'],
  purple: theme.colors['avatar-purple-text'],
  pink: theme.colors['avatar-pink-text'],
  silk: theme.colors['avatar-silk-text'],
  wheat: theme.colors['avatar-wheat-text'],
  blond: theme.colors['avatar-blond-text'],
}

const BACKGROUNDS = {
  celeste: theme.colors['avatar-celeste-background'],
  blue: theme.colors['avatar-blue-background'],
  lavender: theme.colors['avatar-lavender-background'],
  purple: theme.colors['avatar-purple-background'],
  pink: theme.colors['avatar-pink-background'],
  silk: theme.colors['avatar-silk-background'],
  wheat: theme.colors['avatar-wheat-background'],
  blond: theme.colors['avatar-blond-background'],
}

const AVATAR_COLORS = Object.keys(FOREGROUNDS)

function getColorName(name: string | undefined) {
  if (name === undefined) {
    return null
  }

  return AVATAR_COLORS[hash(name, AVATAR_COLORS.length)]
}

function getColorStyles(color: AvatarColor) {
  switch (color) {
    case 'auto':
      return {
        color: theme.colors['grey-60'],
        backgroundColor: theme.colors['grey-10'],
        'button:enabled:hover &': { backgroundColor: theme.colors['grey-15'] },
      }
    case 'done':
      return {
        color: theme.colors['black'],
        backgroundColor: theme.colors['light-green-30'],
      }
    case 'deleted':
      return {
        color: theme.colors['light-red'],
        backgroundColor: theme.colors['diff-remove-background'],
      }
    case 'new':
      return {
        color: theme.colors['dark-blue'],
        backgroundColor: theme.colors['light-blue-40'],
      }
    case 'disabled':
      return {
        color: theme.colors['grey-60'],
        backgroundColor: theme.colors['grey-2'],
      }
    default:
      return {
        color: FOREGROUNDS[color],
        backgroundColor: BACKGROUNDS[color],
      }
  }
}

const AvatarBase = styled(Grid, {
  placeItems: 'center',
  transition: 'background-color 0.3s',
  variants: {
    variant: {
      round: { borderRadius: theme.radii['round'] },
      square: {},
    },
    size: {
      small: { size: 24, ...typography['avatar-small'] },
      medium: { size: 32, ...typography['avatar-medium'] },
      large: { size: 40, ...typography['avatar-large'] },
    },
  },
  compoundVariants: [
    { variant: 'square', size: 'small', css: { borderRadius: theme.radii[4] } },
    { variant: 'square', size: 'medium', css: { borderRadius: theme.radii[8] } },
    { variant: 'square', size: 'large', css: { borderRadius: theme.radii[8] } },
  ],
  defaultVariants: {
    variant: 'round',
    size: 'medium',
  },
})

export interface AvatarProps extends React.ComponentProps<typeof AvatarBase> {
  name?: string | null | undefined
  uuid?: string
  color?: AvatarColor
}

export function Avatar({
  uuid,
  name,
  color,
  variant,
  css,
  children,
  ...rest
}: AvatarProps) {
  return (
    <AvatarBase
      variant={variant}
      css={{ ...getColorStyles(color ?? getColorName(uuid) ?? 'auto'), ...css }}
      {...rest}
    >
      {children ?? (name ? abbreviate(name)?.toUpperCase() : '?')}
    </AvatarBase>
  )
}

import type { CompanyId } from 'domains/company/types'
import { useExpenseTransactions } from 'domains/expense/queries'
import type { ExpenseTransaction } from 'domains/expense/types'
import type { UseQueryOptions } from 'kitchen/types'
import { isNonEmpty } from 'kitchen/utils/helpers'
import { match } from 'ts-pattern'

export const useShouldPromoteExpensesFeature = (
  companyId: CompanyId,
  options?: Pick<UseQueryOptions<ExpenseTransaction[]>, 'suspense' | 'enabled'>
) => {
  const hasExpensesQuery = useExpenseTransactions(
    {
      filter: {
        search: '',
        companyId,
      },
    },
    {
      select: isNonEmpty,
      ...options,
    }
  )
  return match(hasExpensesQuery)
    .with({ status: 'error' }, () => false)
    .with({ status: 'loading' }, () => false)
    .with({ status: 'success' }, (hasExpenses) => hasExpenses.data === false)
    .exhaustive()
}

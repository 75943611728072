import { useQuery } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import type { QueryHookFactory } from 'kitchen/types'
import { authorizedApi } from 'kitchen/utils/api'
import { useDebouncedRequest } from 'kitchen/utils/fetch'
import { getExpenseTransactions } from './requests'
import type { ExpenseTransaction, ExpenseTransactionsPayload } from './types'

export const useExpenseTransactions: QueryHookFactory<
  ExpenseTransactionsPayload,
  ExpenseTransaction[]
> = (payload, options) => {
  const debouncedRequest = useDebouncedRequest()

  return useQuery({
    queryKey: [
      CacheKey.EXPENSE_TRANSACTIONS,
      payload.filter.companyId,
      payload.filter.search,
      payload.filter.cardIds,
    ],
    queryFn: ({ signal }) =>
      debouncedRequest(
        () => getExpenseTransactions(authorizedApi, payload, signal),
        signal
      ),
    ...options,
  })
}

import { useControlled } from 'kitchen/hooks/use-controlled'
import * as Icons from '../../icons'
import { Input } from '../input'
import { Spinner } from '../spinner'
import * as SearchInputRounded from './search-input-rounded'

interface SearchProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  defaultValue?: string
  value?: string
  onValueChange?: (next: string) => void
  loading?: boolean
  size?: 'small' | 'medium'
}

export const SearchInput = ({
  defaultValue = '',
  value: controlledValue,
  onValueChange,
  disabled,
  loading,
  size = 'small',
  ...rest
}: SearchProps) => {
  const [value, setValue] = useControlled({
    defaultValue,
    value: controlledValue,
    onChange: onValueChange,
  })

  return (
    <SearchInputRounded.Root aria-disabled={disabled} size={size}>
      <SearchInputRounded.Start>
        <Icons.S16.Search />
      </SearchInputRounded.Start>
      <Input
        type="search"
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        disabled={disabled}
        {...rest}
      />
      <SearchInputRounded.End>
        {!disabled && !loading && (
          <SearchInputRounded.Clear
            onClick={() => setValue('')}
            style={{ visibility: value === '' ? 'hidden' : 'visible' }}
          />
        )}
        {loading && <Spinner size={16} color="grey-40" />}
      </SearchInputRounded.End>
    </SearchInputRounded.Root>
  )
}

import type { ApiRequest } from 'kitchen/types'
import { checkIsNotFoundError } from 'kitchen/utils/error'
import type { AppNotification } from '../types/apron'

export const getAppNotifications: ApiRequest<void, AppNotification[]> = (fetch, signal) =>
  fetch
    .get(`/apron/app-notifications`, { signal })
    .json<AppNotification[]>()
    .catch((error) => {
      if (checkIsNotFoundError(error)) {
        return []
      }

      throw error
    })

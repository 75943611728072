const rotation = {
  default: 0,
  pressed: 180,
} as const

interface DropdownProps extends React.SVGAttributes<SVGElement> {
  variant?: keyof typeof rotation
}

export const Dropdown = ({ variant = 'default', ...rest }: DropdownProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={{ transform: `rotate(${rotation[variant]}deg)` }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8 12 6-6-1.41-1.41L8 9.17 3.41 4.6 2 6l6 6Z"
      clipRule="evenodd"
    />
  </svg>
)

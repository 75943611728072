import { useQuery } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import type { InferQuery, InferQueryHook } from 'kitchen/types'
import { authorizedApi } from 'kitchen/utils/api'
import {
  getPaymentCyclesSetupSchedule,
  getPaymentCycleSetup,
  getPaymentCycles,
  getPaymentCyclesNotificationSettings,
} from './requests'

export abstract class PaymentCycleQueries {
  static setup: InferQuery<typeof getPaymentCycleSetup> = (payload) => ({
    queryKey: [CacheKey.PAYMENT_CYCLES_SETUP, payload.companyId],
    queryFn: ({ signal }) => getPaymentCycleSetup(authorizedApi, payload, signal),
  })
  static list: InferQuery<typeof getPaymentCycles> = ({
    companyId,
    exclude,
    issueDateFrom,
    issueDateTo,
    dueDateFrom,
    dueDateTo,
  }) => ({
    queryKey: [exclude, issueDateFrom, issueDateTo, dueDateFrom, dueDateTo].some(Boolean)
      ? [
          CacheKey.PAYMENT_CYCLES,
          companyId,
          { exclude, issueDateFrom, issueDateTo, dueDateFrom, dueDateTo },
        ]
      : [CacheKey.PAYMENT_CYCLES, companyId],
    queryFn: ({ signal }) =>
      getPaymentCycles(
        authorizedApi,
        { companyId, exclude, issueDateFrom, issueDateTo, dueDateFrom, dueDateTo },
        signal
      ),
  })
  static listSetupSchedule: InferQuery<typeof getPaymentCyclesSetupSchedule> = (
    payload
  ) => ({
    queryKey: [CacheKey.PAYMENT_CYCLES_SETUP_SCHEDULE, payload.companyId],
    queryFn: ({ signal }) =>
      getPaymentCyclesSetupSchedule(authorizedApi, payload, signal),
  })
  static notificationSettings: InferQuery<typeof getPaymentCyclesNotificationSettings> = (
    payload
  ) => ({
    queryKey: [CacheKey.PAYMENT_CYCLES_NOTIFICATION_SETTINGS, payload.companyId],
    queryFn: ({ signal }) =>
      getPaymentCyclesNotificationSettings(authorizedApi, payload, signal),
  })
}

export const usePaymentCycleSetup: InferQueryHook<typeof PaymentCycleQueries.setup> = (
  payload,
  options
) =>
  useQuery({
    ...PaymentCycleQueries.setup(payload),
    ...options,
  })

export const usePaymentCycles: InferQueryHook<typeof PaymentCycleQueries.list> = (
  payload,
  options
) =>
  useQuery({
    ...PaymentCycleQueries.list(payload),
    ...options,
  })

export const useListPaymentCycleSetupSchedule: InferQueryHook<
  typeof PaymentCycleQueries.listSetupSchedule
> = (payload, options) =>
  useQuery({
    ...PaymentCycleQueries.listSetupSchedule(payload),
    ...options,
  })

export const usePaymentCyclesNotificationSettings: InferQueryHook<
  typeof PaymentCycleQueries.notificationSettings
> = (payload, options) =>
  useQuery({
    ...PaymentCycleQueries.notificationSettings(payload),
    ...options,
  })

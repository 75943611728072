import { z } from 'zod'
import { getIntl } from '../i18n/utils'

export const DayOfWeekSchema = z.enum(
  ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
  {
    errorMap() {
      const { formatMessage } = getIntl()

      return {
        message: formatMessage({
          id: 'date.day-of-week.required',
          defaultMessage: 'Please select an option',
        }),
      }
    },
  }
)

export const DayOfWeekIndexSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
])

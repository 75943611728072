import * as formats from 'kitchen/utils/formats'
import { useCallback } from 'react'
import { useRifm } from 'rifm'
import type { PhoneCountryCallingCode } from '../types'

interface PhoneRifmOptions extends Omit<Parameters<typeof useRifm>[0], 'format'> {
  phoneCountryCallingCode: PhoneCountryCallingCode | null
}

export function usePhoneRifm({ phoneCountryCallingCode, ...options }: PhoneRifmOptions) {
  const format = useCallback(
    (value: string) => {
      if (!phoneCountryCallingCode) {
        return formats.number(value)
      }

      const internationalCode = `+${phoneCountryCallingCode.callingCode}`

      if (
        value.startsWith(internationalCode) &&
        value.length > internationalCode.length
      ) {
        return formats.number(value.replace(internationalCode, ''))
      }

      return formats.number(value)
    },
    [phoneCountryCallingCode]
  )

  return useRifm({
    ...options,
    accept: /(\d|\+)+/g,
    format,
  })
}

import type { Company } from 'api/types/companies'
import type { UserRole } from 'api/types/permissions'

import type { UserId } from 'api/types/users'
import { ImpossibleError } from './error'
import { getCompanyUser } from './users'

export function getCompanyUserRole(userId: UserId, company: Company) {
  const companyUser = getCompanyUser(userId, company)

  return companyUser?.preset
}

export function formatRoleName(role: UserRole | undefined): string {
  if (role === undefined) {
    return ''
  }

  switch (role) {
    case 'CREATOR':
      return 'Creator'
    case 'APPROVER':
      return 'Approver'
    case 'PAYER':
      return 'Payer'
    case 'ADMIN':
      return 'Admin'
    case 'CUSTOM':
      return 'Custom role'
    case 'BOOKKEEPER':
      return 'Bookkeeper'
    case 'EMPLOYEE':
      return 'Employee'
    case 'OWNER':
      return 'Owner'
    default:
      throw new ImpossibleError('unhandled role', role)
  }
}

import { useAuthSession } from 'domains/auth/queries'
import { Time } from 'kitchen/constants'
import { FetchProvider } from 'kitchen/context/fetch'
import { Navigate, useLocation, useSearchParams } from 'kitchen/router'
import { setLogoutLocation } from 'kitchen/utils/auth'
import { Authorized } from '@app/components/authorized/authorized'
import { LoadingStatus } from '@app/components/loading-status'
import { Path } from '@app/constants'

interface RequireAuthProps {
  layout?: 'flow-page' | 'halves-onboarding'
  children?: React.ReactNode
}

export const RequireAuth = ({ layout = 'flow-page', children }: RequireAuthProps) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const authSession = useAuthSession()

  switch (authSession.status) {
    case 'loading':
      return <LoadingStatus layout={layout} />
    case 'error':
      /* Save the current location they were trying to go to when they were redirected.
        This allows us to send them back to that page after they login. */
      setLogoutLocation(location)

      const email = searchParams.get('email')
      const flow = searchParams.get('flow')

      /**
       * Redirect to proper signup page, with email already prefilled
       * Task: PAY-547
       * Template: `sign-up-invitation`
       * Connected PR: https://github.com/Hello-Cash-Ltd/hellocash-mail-templates/pull/213
       */
      const isAcceptInviteRedirect = email !== null && flow === 'accept-invite'

      return (
        <Navigate
          to={
            isAcceptInviteRedirect
              ? { pathname: Path.AUTH_EMAIL, search: searchParams.toString() }
              : Path.AUTH
          }
          replace
        />
      )
    case 'success':
      return (
        <>
          <FetchProvider>
            <Authorized layout={layout}>{children}</Authorized>
          </FetchProvider>
          {authSession.isRefetching && (
            <LoadingStatus layout="overlay" delay={Time.SECOND} />
          )}
        </>
      )
  }
}

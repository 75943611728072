import { Slot } from 'radix-ui'
import { forwardRef } from 'react'
import { ButtonBase, type ButtonBaseProps } from '../../primitives'

export interface NestedInteractiveProps extends ButtonBaseProps {
  action?: string
}

/**
 * a structural component to nest interactive elements like buttons. An alternative to ZStack
 */
const NestedInteractive = forwardRef<HTMLElement, NestedInteractiveProps>(
  function NestedInteractiveRef({ action, children, onClick, ...rest }, forwardRef) {
    return (
      <Slot.Root
        ref={forwardRef}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          if (
            rest.disabled ||
            event.defaultPrevented ||
            (event.target instanceof Element &&
              (!event.currentTarget.contains(event.target) ||
                event.target.closest('button, input, select, a')))
          ) {
            return
          }

          const actionElement = event.currentTarget.querySelector<HTMLElement>(
            action === undefined
              ? '[data-nested-interactive-action]'
              : `[data-nested-interactive-action="${action}"]`
          )

          if (actionElement) {
            actionElement.dataset.stopPropagation = ''
            actionElement.click()
            delete actionElement.dataset.stopPropagation
          } else {
            onClick?.(event)
          }
        }}
        {...rest}
      >
        {children}
      </Slot.Root>
    )
  }
)

export interface NestedInteractiveActionProps extends Omit<ButtonBaseProps, 'name'> {
  name?: string | true
  asChild?: boolean
}

const NestedInteractiveAction = forwardRef<
  HTMLButtonElement,
  NestedInteractiveActionProps
>(function NestedInteractiveActionRef(
  { name = true, asChild, onClick, ...rest },
  forwardedRef
) {
  const Comp = asChild ? Slot.Root : ButtonBase
  return (
    <Comp
      data-nested-interactive-action={name}
      ref={forwardedRef}
      onClick={(event) => {
        if ('stopPropagation' in event.currentTarget.dataset) {
          event.stopPropagation()
        }

        onClick?.(event)
      }}
      {...rest}
    />
  )
})

export { NestedInteractive as Root, NestedInteractiveAction as Action }

import * as Sentry from '@sentry/react'
import { rudderAnalytics } from 'domains/analytics/rudderstack'
import { getSessionId } from 'domains/analytics/utils'
import { useAuthSession } from 'domains/auth/queries'
import { useMatchesPatterns } from 'kitchen/router'
import { removeCookie } from 'kitchen/utils/cookies'
import { AuthError } from 'kitchen/utils/error'
import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { Path } from '../constants'

/*
  Subscribes to auth session and cleans up if it logs out.
  This should be called at the app root, because we need to check and cleanup the session
  at all routes, including public ones.
 */
export const useCleanupUserSession = () => {
  const intercom = useIntercom()
  const isAuthCallbackPage = useMatchesPatterns([{ path: Path.AUTH_CONNECT_VENDOR }])
  const authSession = useAuthSession({ enabled: !isAuthCallbackPage })

  // not using isAuthenticated here bc we want a negative lookup,
  // i.e. "when logged out", not "when not logged in".
  // The latter will fire a false-positive until session is loaded.
  const isSignedOut =
    authSession.error instanceof AuthError ||
    (authSession.status === 'success' && authSession.data === undefined)

  return useEffect(() => {
    if (isSignedOut && !isAuthCallbackPage) {
      Sentry.setUser(null)
      rudderAnalytics.reset()
      intercom.shutdown()
      // for some reason intercom.shutdown() does not always remove its cookies
      removeCookie(`intercom-session-${process.env.INTERCOM_APP_ID}`, '.getapron.com')
      removeCookie(`intercom-id-${process.env.INTERCOM_APP_ID}`, '.getapron.com')
      // reboot intercom
      intercom.boot({
        customAttributes: {
          env: process.env.BUILD_MODE,
          session_id: getSessionId(),
        },
      })
    }
  }, [isSignedOut, isAuthCallbackPage, intercom])
}

import type { User } from 'api/types/users'
import type { SignupVendorResponse, ConnectVendorResponse } from 'api/types/vendors'
import type { AuthMode, UserJwtTokenPayload } from 'domains/auth/types'
import { ensure } from 'kitchen/utils/helpers'
import { z } from 'zod'
import { formatUserDetails } from '../user/utils'
import { AuthModeSchema } from './schemas'

const AuthResponseStateParamsSchema = z.object({
  state: z.string(),
  authMode: AuthModeSchema,
  successReturnPath: z.string(),
  fallbackReturnPath: z.string(),
})

interface AuthResponseRedirectPayload {
  authMode: AuthMode
  response: SignupVendorResponse | ConnectVendorResponse
  successReturnPath: string
  fallbackReturnPath: string
  replace?: boolean
}

// piggy-back on BE-generated state param to get it back in Vendor callback response.
export function authResponseRedirect(input: AuthResponseRedirectPayload) {
  const url = new URL(input.response.location)
  const state = ensure(url.searchParams.get('state'))

  const value = JSON.stringify(
    AuthResponseStateParamsSchema.parse({
      state,
      authMode: input.authMode,
      successReturnPath: input.successReturnPath,
      fallbackReturnPath: input.fallbackReturnPath,
    })
  )

  url.searchParams.set('state', value)

  if (input.replace === true) {
    window.location.replace(url)
  } else {
    window.location.assign(url)
  }
}

export function parseAuthResponseState(input: URLSearchParams) {
  const state = ensure(input.get('state'))
  return AuthResponseStateParamsSchema.parse(JSON.parse(state))
}

export function decodeJwtPayload(accessToken: string) {
  const content: UserJwtTokenPayload = JSON.parse(atob(accessToken.split('.')[1]))
  return content
}

export function getForce2FaInitiatorDetails(initiator: User) {
  return formatUserDetails(initiator, { fallbackToEmail: true, showEmail: true })
}
